import { Button } from "@/components/ui/button";
import { LabelAndValue } from "../DefaultComponents";
import { MoveLeftIcon, Pencil, Trash } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RelationsFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TView = {
  viewData: z.infer<typeof RelationsFormSchema>;
};

const RelationsView = ({ viewData }: TView) => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ userId }: { userId: string | undefined }) => {
      return axiosInstance.delete(`user/${userId}/relations`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userId, "relations"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutate({ userId });
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-xl">
              Relations in Canada or Australia
            </CardTitle>
            <CardDescription>
              User Relations Details in Canada or Australia
            </CardDescription>
          </div>
          <div className="flex flex-row gap-4">
            <Link to="edit" state={{ preFormData: viewData }}>
              <Button variant={`outline`}>
                <Pencil className="mr-2 w-4 h-4" /> Edit
              </Button>
            </Link>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardHeader>
      <Separator />
      <CardContent>
        <div className="flex flex-col mt-4">
          <LabelAndValue
            label="Are you currently a full-time student?"
            value={viewData.full_time_student}
          />
          <LabelAndValue
            label="Have you been a full-time student and substantially dependent on your parents for financial support since before the age of 19?"
            value={viewData.full_time_student_parents_dependent}
          />
          <LabelAndValue
            label="Do you or, if applicable your accompanying spouse, or common-law partner have a blood relative living in Canada/Australia who is a citizen or a permanent resident of Canada/Australia?"
            value={viewData.have_blood_relative_in_can_or_aus}
          />
          <LabelAndValue
            label="Their relationship with you?"
            value={viewData.relative_rel_with_you}
          />
          <LabelAndValue
            label="How old is your relative?"
            value={viewData.rel_age}
          />

          <LabelAndValue
            label="What is the employment status of your relative?"
            value={viewData.rel_emp_status}
          />

          <LabelAndValue
            label="How many people is this relative financially responsible for in his/her household in Canada/Australia?"
            value={viewData.no_of_people_rel_fin_resp_household_in_can_or_aus}
          />
          <LabelAndValue
            label="How much is the annual household income of your relative? This includes the combined annual income of your relative and his/her spouse."
            value={viewData.rel_annual_income}
          />

          <LabelAndValue
            label="Does this relative wish to sponsor you? If unsure, please choose No."
            value={viewData.relative_wish_to_sponser}
          />
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="mt-4 flex justify-between">
        <Link to="../work-in-can-aus">
          <Button variant={`outline`}>
            <MoveLeftIcon className="mr-2 w-4 h-4" /> Work Experience in Canada
            / Australia
          </Button>
        </Link>
        {/* <Link to="../relations">
          <Button variant={`outline`}>
            Relations in Canada / Australia
            <MoveRightIcon className="ml-2 w-4 h-4" />
          </Button>
        </Link> */}
      </CardFooter>
    </Card>
  );
};

export default RelationsView;
