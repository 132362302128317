import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { useParams } from "react-router-dom";
import { HIGHEST_EDU_LEVEL } from "@/data/assessment.data";
import { EducationFormSchema } from "@/components/FormInterfaces/Assessment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useStore from "@/lib/store";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const EducationForm = ({
  preFormData,
  closeDialog,
}: {
  preFormData?: z.infer<typeof EducationFormSchema> & { _id: string };
  closeDialog?: (value: boolean) => void;
}) => {
  const axiosInstance = useAxiosAuthInstance();
  const recordId = preFormData && preFormData._id;
  const { changeCreateDialogState } = useStore();
  const { type, userId } = useParams();
  const form = useForm<z.infer<typeof EducationFormSchema>>({
    resolver: zodResolver(EducationFormSchema),
    defaultValues: {
      for: type,
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof EducationFormSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(
          `user/${userId}/educations/${recordId}/${type}`,
          formData
        );
      } else {
        return await axiosInstance.post(
          `user/${userId}/educations/${type}`,
          formData
        );
      }
    },
    onSuccess: (res) => {
      if (preFormData && closeDialog) {
        closeDialog(false);
      } else {
        changeCreateDialogState(false);
      }
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, type, "educations"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof EducationFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 gap-8">
          <FormField
            control={form.control}
            name="highest_level_of_education"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Highest Level of Education</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {HIGHEST_EDU_LEVEL.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name_of_education"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Name of the Education</FormLabel>
                <FormControl>
                  <Input placeholder="Name of the Education" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="field_of_study"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Field of Study</FormLabel>
                <FormControl>
                  <Input placeholder="Field of Study" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="board_or_university"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Board Or University</FormLabel>
                <FormControl>
                  <Input placeholder="Board Or University" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="state_of_study"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>State of Study</FormLabel>
                <FormControl>
                  <Input placeholder="State of Study" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="country_of_study"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Country of Study</FormLabel>
                <FormControl>
                  <Input placeholder="Country of Study" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="type_of_educational_institute"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type of Education</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="Government">Government</SelectItem>
                    <SelectItem value="Private">Private</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end">
          <Button type="submit" className="bg-primary hover:bg-primary/80 my-4">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default EducationForm;
