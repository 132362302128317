import { jwtDecode } from "jwt-decode";
import useToken from "./useToken";

type decodeTokenProps = {
  name: string;
  username: string;
  roles: string[];
};

export const useDecodeToken = (): decodeTokenProps | null => {
  const { authToken } = useToken();
  let decodedToken: decodeTokenProps | null = null;
  if (authToken) {
    decodedToken = jwtDecode(authToken);
  }
  return decodedToken;
};
