import { DataTable } from "@/components/data-table";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { DebounceInput } from "react-debounce-input";
import { PaymentCols } from "./PaymentCols";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const Payments = () => {
  const axiosInstance = useAxiosAuthInstance();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["adminPayments", pageIndex, pageSize, globalFilter],
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get(
        `/admin/payments/paginated?currentPage=${pageIndex}&pageSize=${pageSize}&search=${globalFilter}`,
        {
          signal,
        }
      );
      return res.data;
    },
    placeholderData: keepPreviousData,
  });

  const rows = useMemo(() => data?.data?.rows, [data]);
  const pageCount = useMemo(() => data?.data?.pageCount, [data]);
  const columns = useMemo(() => PaymentCols, [PaymentCols]);

  useEffect(() => {
    if (globalFilter) {
      // Update pagination state with pageIndex 0 and keep the same pageSize
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
    }
  }, [globalFilter]);

  if (isPending) return <p>loading...</p>;
  if (isError) return <p>{error.message}</p>;

  return (
    <div>
      <div className="flex items-center py-4">
        <DebounceInput
          className="p-2 border"
          debounceTimeout={500}
          placeholder="Search..."
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
        />
      </div>
      <DataTable
        columns={columns}
        data={rows}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={pageCount}
      />
    </div>
  );
};

export default Payments;
