type AssessmentMenuProps = {
  menu_title: string;
  menu_link: string;
};

export const AssessmentMenu: AssessmentMenuProps[] = [
  {
    menu_title: "Education Details",
    menu_link: "educations",
  },
  {
    menu_title: "Language Details",
    menu_link: "languages",
  },
  {
    menu_title: "Work Experience",
    menu_link: "work-experience",
  },
  {
    menu_title: "Work in Canada/Australia",
    menu_link: "work-in-can-aus",
  },
  {
    menu_title: "Relations in Canada/Australia",
    menu_link: "relations",
  },
];
