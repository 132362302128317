import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { AssessmentMenu } from "@/lib/Constants";
import { NavLink, Outlet, useParams } from "react-router-dom";

const AssessmentLayout = () => {
  let { type } = useParams();
  let filteredMenu =
    type === "spouse" ? AssessmentMenu.slice(0, 3) : AssessmentMenu;
  return (
    <Card>
      <CardHeader>
        <CardTitle>{type === "self" ? "Self" : "Spouse"} Assessment</CardTitle>
        <CardDescription>User Assessment Details</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <div className="flex flex-row flex-wrap items-center gap-2">
          {filteredMenu.map((item) => {
            return <TabComponent {...item} />;
          })}
        </div>
        <Outlet />
      </CardContent>
    </Card>
  );
};

type TabComponentProps = {
  menu_title: string;
  menu_link: string;
};

const TabComponent = ({ menu_title, menu_link }: TabComponentProps) => {
  return (
    <NavLink to={menu_link ? menu_link : "#"}>
      {({ isActive }) =>
        isActive ? (
          <Button className="bg-primary">{menu_title}</Button>
        ) : (
          <Button variant={`secondary`}>{menu_title}</Button>
        )
      }
    </NavLink>
  );
};

export default AssessmentLayout;
