// useToken.ts
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useTokenStore } from "@/lib/store";

interface TokenHookResult {
  authToken: string | null;
}

const useToken = (): TokenHookResult => {
  const { authToken, deleteToken } = useTokenStore();
  const cookieToken = Cookies.get("authToken");
  const selectedToken = cookieToken || authToken;
  useEffect(() => {
    if (authToken !== cookieToken) {
      deleteToken();
    }
  }, [authToken, cookieToken, deleteToken]);
  return {
    authToken: selectedToken,
  };
};

export default useToken;
