import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  PaginationState,
  RowSelectionState,
  SortingState,
  ColumnFiltersState,
  GlobalFilterTableState,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { WebLeadCols } from "./WebLeadCols";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { ControlledPagination } from "@/components/controlled-pagination";
import { Button } from "@/components/ui/button";
import { FilterIcon } from "lucide-react";
import { DebounceInput } from "react-debounce-input";

const WebLeadsDataTable = () => {
  const axiosInstance = useAxiosAuthInstance();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] =
    useState<GlobalFilterTableState["globalFilter"]>(null);
  const columns = useMemo(() => WebLeadCols, [WebLeadCols]);

  const queryParams = useMemo(
    () => ({
      currentPage: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sorting: sorting.map(({ id, desc }) => ({ id, desc })),
      search: globalFilter,
    }),
    [pagination.pageIndex, pagination.pageSize, sorting, globalFilter]
  );

  //   Data Fetching
  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      "webLeads",
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      globalFilter,
    ],
    queryFn: async () => {
      try {
        const response = await axiosInstance.post(
          "/admin/web-leads/paginated",
          queryParams
        );
        return response.data; // Return the data directly
      } catch (err) {
        throw err; // Re-throw the error to be handled by useQuery
      }
    },
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    data: data?.rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: data?.pageCount || 0,
    state: {
      pagination,
      rowSelection,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
  });

  useEffect(() => {
    console.log("column filters", columnFilters);
  }, [columnFilters]);

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center">
        <DebounceInput
          className="p-2 border"
          debounceTimeout={500}
          placeholder="Search..."
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
        />
      </div>
      <div className="card bg-white">
        <div className="card-body">
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        <div className="flex flex-row gap-2">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanFilter() ? (
                            <Button
                              className="border"
                              variant={`ghost`}
                              size={`icon`}
                            >
                              <FilterIcon className="h-4 w-4" />
                            </Button>
                          ) : null}
                        </div>
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() ? "selected" : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebLeadsDataTable;
