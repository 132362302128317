import { NavLink, Outlet } from "react-router-dom";
import { crmMenu, dashboardMenu, empManageMenu, webMenu } from "@/data/sidebar";
import { ReactNode } from "react";
import Logo from "@/assets/logo.png";
import NotificationsBar from "@/components/Misc/NotificationsBar";

type TMenuItem = {
  menu_title: string;
  menu_link: string;
  icon: ReactNode;
};

const DashboardLayout = () => {
  return (
    <div className="container-fluid flex bg-slate-100">
      {/* Side Menu Start */}
      <SideMenu />
      {/* Side Menu End */}

      {/* Dashboard Layout Start */}
      <div className="flex-1 overflow-y-auto ml-64 min-h-screen pb-5">
        <NotificationsBar />
        <div className="px-8 overflow-auto">
          <Outlet />
        </div>
      </div>
      {/* Dashboard Layout End */}
    </div>
  );
};

const MenuItem = ({ menu_title, menu_link, icon }: TMenuItem) => {
  return (
    <NavLink
      to={menu_link ? menu_link : "#"}
      className={({ isActive }: { isActive: boolean }): string => {
        return isActive
          ? `flex flex-row items-center justify-between w-full bg-violet-500 text-slate-200 rounded-md px-3 py-2`
          : `flex flex-row items-center text-slate-200 hover:text-white justify-between w-full rounded-md px-3 py-2`;
      }}
    >
      <span className="flex items-center gap-2">
        {icon}
        {menu_title}
      </span>
    </NavLink>
  );
};

const SideMenu = () => {
  return (
    <div className="fixed top-0 w-64 p-2 flex flex-col h-full bg-violet-600">
      <img src={Logo} alt="skyland Immigration" className="w-48 mx-auto py-2" />

      <div className="flex flex-col gap-4 mx-2 pt-4">
        <section>
          <p className="uppercase my-2 text-slate-100 text-xs underline">
            Analytics and Reports
          </p>
          {dashboardMenu.map((item, index: number) => {
            return (
              <ul className="flex flex-col w-full" key={index}>
                <MenuItem {...item} />
              </ul>
            );
          })}
        </section>

        <section>
          <p className="uppercase my-2 text-slate-100 text-xs underline">
            Lead Channels
          </p>
          {webMenu.map((item, index: number) => {
            return (
              <ul className="flex flex-col w-full" key={index}>
                <MenuItem {...item} />
              </ul>
            );
          })}
        </section>

        <section>
          <p className="uppercase my-2 text-slate-100 text-xs underline">Crm</p>
          {crmMenu.map((item, index: number) => {
            return (
              <ul className="flex flex-col w-full" key={index}>
                <MenuItem {...item} />
              </ul>
            );
          })}
        </section>

        <section>
          <p className="uppercase my-2 text-slate-100 text-xs underline">
            Employee Management
          </p>
          {empManageMenu.map((item, index: number) => {
            return (
              <ul className="flex flex-col w-full" key={index}>
                <MenuItem {...item} />
              </ul>
            );
          })}
        </section>
      </div>
      <div></div>
    </div>
  );
};

export default DashboardLayout;
