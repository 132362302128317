import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

type formHandlerOptionsProps = {
  formSchema: any;
  postUrl?: string;
  patchUrl?: string;
  mode: "CREATE" | "EDIT";
};

const useFormHandler = ({
  formSchema,
  postUrl = "",
  patchUrl = "",
  mode,
}: formHandlerOptionsProps) => {
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorResponse, setErrorResponse] = useState<Error | undefined>();
  const axiosInstance = useAxiosAuthInstance();

  const mutation = useMutation({
    mutationFn: async (formData) => {
      if (mode === "CREATE") {
        return await axiosInstance.post(postUrl, formData);
      } else if (mode === "EDIT") {
        return await axiosInstance.patch(patchUrl, formData);
      }
    },
    onSuccess: (res) => {
      setSuccessMessage(res?.data?.message);
    },
    onError: (error) => {
      setErrorResponse(error);
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (formData) => {
    mutation.mutateAsync(formData);
  };

  return {
    onSubmit,
    successMessage,
    errorResponse,
  };
};

export default useFormHandler;
