import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type ChartDataProps = {
  _id: string;
  name: string;
  count: number;
};

const EmployeeWiseCallsReport = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { data, isPending, isError, error } = useQuery<ChartDataProps[]>({
    queryKey: ["employeeWiseCalls"],
    queryFn: async () =>
      await axiosInstance
        .get("/admin/reports/all-emp-calls")
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  if (isPending) return <p>Fetching Data....</p>;

  if (isError) return <p>{error.message}</p>;

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20,
            bottom: 50,
            left: 10,
            right: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={-60} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8">
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EmployeeWiseCallsReport;
