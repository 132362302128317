import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { LANGUAGE_SKILL, LANGUAGE_SKILL_VALUES } from "@/data/assessment.data";
import { LanguagesFormSchema } from "@/components/FormInterfaces/Assessment";
import useStore from "@/lib/store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const LanguagesForm = ({
  preFormData,
  closeDialog,
}: {
  preFormData?: z.infer<typeof LanguagesFormSchema> & { _id: string };
  closeDialog?: (value: boolean) => void;
}) => {
  const axiosInstance = useAxiosAuthInstance();
  const recordId = preFormData && preFormData._id;
  const { changeCreateDialogState } = useStore();
  const { userId, type } = useParams();
  const form = useForm<z.infer<typeof LanguagesFormSchema>>({
    resolver: zodResolver(LanguagesFormSchema),
    defaultValues: {
      for: type,
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof LanguagesFormSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(
          `user/${userId}/languages/${recordId}/${type}`,
          formData
        );
      } else {
        return await axiosInstance.post(
          `user/${userId}/languages/${type}`,
          formData
        );
      }
    },
    onSuccess: (res) => {
      if (preFormData && closeDialog) {
        closeDialog(false);
      } else {
        changeCreateDialogState(false);
      }
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, type, "languages"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof LanguagesFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="language_skill"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Language Skill</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger className="w-[250px]">
                    <SelectValue placeholder="Language Skill" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {LANGUAGE_SKILL.map((item, index: number) => {
                    return (
                      <SelectItem value={item} key={index}>
                        {item}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid md:grid-cols-2 gap-4 mt-3">
          <FormField
            control={form.control}
            name="speak"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Speak Level</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Speak Level" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {LANGUAGE_SKILL_VALUES.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="read"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Read Level</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Language Skill" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {LANGUAGE_SKILL_VALUES.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="write"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Write Level</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Write Level" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {LANGUAGE_SKILL_VALUES.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="understand"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Understand Level</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Understand Level" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {LANGUAGE_SKILL_VALUES.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex flex-row justify-end mt-4">
          <Button type="submit" className="my-4 bg-primary hover:bg-primary/80">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LanguagesForm;
