import { PaginationState } from "@tanstack/react-table";
import { DebounceInput } from "react-debounce-input";
import { DataTable } from "@/components/data-table";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { EmpCols } from "./EmpCols";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import EmployeeForm from "@/components/Forms/Employee/EmployeeForm";
import { Separator } from "@/components/ui/separator";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import queryString from "query-string";

const Employees = () => {
  const axiosInstance = useAxiosAuthInstance();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const queryParams = {
    currentPage: String(pageIndex), // Convert number to string
    pageSize: String(pageSize), // Convert number to string
    search: globalFilter,
  };

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["employees", pageIndex, pageSize, globalFilter],
    queryFn: async () =>
      await axiosInstance
        .get(
          `/admin/employees/paginated?${queryString.stringify(queryParams, {
            arrayFormat: "bracket",
          })}`
        )
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
    placeholderData: keepPreviousData,
  });
  console.log("employees", data);
  const rows = useMemo(() => data?.data?.rows, [data]);
  const pageCount = useMemo(() => data?.data?.pageCount, [data]);
  const columns = useMemo(() => EmpCols, [EmpCols]);
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogClose = (newValue: boolean) => {
    setIsOpen(newValue);
  };

  useEffect(() => {
    if (globalFilter) {
      // Update pagination state with pageIndex 0 and keep the same pageSize
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
    }
  }, [globalFilter]);

  if (isPending) return <p>Loading...</p>;
  if (isError) return <p>{error.message}</p>;

  return (
    <div>
      <div className="flex items-center py-4 justify-between">
        <DebounceInput
          className="p-2 border"
          debounceTimeout={500}
          placeholder="Search..."
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(String(event.target.value))}
        />
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger asChild>
            <Button className="bg-primary hover:bg-primary/80">
              <Plus className="mr-2 w-4 h-4" /> Add New Employee
            </Button>
          </DialogTrigger>
          <DialogContent
            className="sm:max-w-[425px]"
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
          >
            <DialogHeader>
              <DialogTitle>Add New Employee</DialogTitle>
              <DialogDescription>Employee Basic Details</DialogDescription>
            </DialogHeader>
            <Separator />
            <EmployeeForm closeDialog={handleDialogClose} />
          </DialogContent>
        </Dialog>
      </div>
      <DataTable
        columns={columns}
        data={rows}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={pageCount}
      />
    </div>
  );
};

export default Employees;
