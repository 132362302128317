import { Separator } from "@/components/ui/separator";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import EmployeeForm from "@/components/Forms/Employee/EmployeeForm";
import RoleForm from "@/components/Forms/Employee/RoleForm";
import { Button } from "@/components/ui/button";
import { Plus, Trash2Icon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useEffect, useState } from "react";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";

const EmpManage = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { empId } = useParams();
  const [roles, setRoles] = useState<string[]>([]);

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["employee", empId],
    queryFn: async () => await axiosInstance.get(`/employee/${empId}`),
  });

  useEffect(() => {
    if (data?.data && data.data?.roles) {
      console.log("roles", data?.data?.roles);
      setRoles([...data.data?.roles]);
    }
  }, [data?.data]);

  if (isPending) {
    return <p>Checking Resource...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  return (
    <Tabs defaultValue="account">
      <TabsList>
        <TabsTrigger value="account">Employee Account</TabsTrigger>
        <TabsTrigger value="roles">Employee Roles</TabsTrigger>
      </TabsList>
      <TabsContent value="account">
        <div className="flex flex-col gap-4 p-5 bg-white">
          <div>
            <p className="text-xl font-medium">Account Details</p>
            <p className="text-gray-500 text-sm">
              Update Employee Basic Details here
            </p>
          </div>
          <Separator />
          <EmployeeForm preFormData={data?.data} />
        </div>
      </TabsContent>
      <TabsContent value="roles">
        <div className="flex flex-col gap-4 p-5 bg-white">
          <div>
            <p className="text-xl font-medium">Employee Roles</p>
            <p className="text-gray-500 text-sm">
              Assign or Revoke Employee roles here
            </p>
          </div>
          <Separator />
          <div className="flex items-center gap-4">
            {roles &&
              roles.map((role) => (
                <RevokeRoleComponent key={role} role={role} empId={empId} />
              ))}
            <Popover>
              <PopoverTrigger asChild>
                <Button variant={`outline`}>
                  <Plus className="mr-2 w-4 h-4" /> Add Role
                </Button>
              </PopoverTrigger>
              <PopoverContent sideOffset={25} className="w-fit">
                <RoleForm />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </TabsContent>
    </Tabs>
  );
};

type RoleComponentProps = {
  role: string;
  empId: string | undefined;
};

const RevokeRoleComponent = ({ role, empId }: RoleComponentProps) => {
  const axiosInstance = useAxiosAuthInstance();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (empId: string | undefined) =>
      await axiosInstance.post(`admin/employees/${empId}/revoke-role`, {
        role: role,
      }),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["employee", empId],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutateAsync(empId);
  };

  return (
    <div className="flex items-center gap-4 bg-slate-100 text-primary p-2 border rounded-md w-fit">
      <p className="first-letter:uppercase">{role}</p>
      <Trash2Icon
        className="w-4 h-4 text-red-500 hover:cursor-pointer"
        onClick={handleDelete}
      />
    </div>
  );
};

export default EmpManage;
