import { Button } from "@/components/ui/button";
import { LabelAndValue } from "../DefaultComponents";
import { MoveLeftIcon, MoveRightIcon, Pencil, Trash } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { WorkExpCanAusFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TView = {
  viewData: z.infer<typeof WorkExpCanAusFormSchema>;
};

const WorkExpCanAusView = ({ viewData }: TView) => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ userId }: { userId: string | undefined }) => {
      return axiosInstance.delete(`user/${userId}/work-in-can-aus`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userId, "work-in-can-aus"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutate({ userId });
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-xl">
              Work Experience in Canada or Australia
            </CardTitle>
            <CardDescription>
              User Work Experience Details in Canada or Australia
            </CardDescription>
          </div>
          <div className="flex flex-row gap-4">
            <Link to="edit" state={{ preFormData: viewData }}>
              <Button variant={`outline`}>
                <Pencil className="mr-2 w-4 h-4" /> Edit
              </Button>
            </Link>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardHeader>
      <Separator />
      <CardContent>
        <div className="flex flex-col mt-4">
          <LabelAndValue
            label="Have you been in Canada/Australia as a temporary foreign worker?"
            value={viewData.have_been_in_can_or_aus_as_temporary_foreign_worker}
          />
          <LabelAndValue
            label="How many years have you worked full-time in this position in Canada/Australia?"
            value={viewData.years_worked_fulltime_in_can_or_aus}
          />
          <LabelAndValue
            label="Currently employed in Canada/Australia?"
            value={viewData.currently_employeed_in_can_or_aus}
          />
          <LabelAndValue
            label="When did you leave your employment in Canada/Australia?"
            value={viewData.left_employement_in_can_or_aus}
          />
          <LabelAndValue
            label="Have an Ofiicial offer of Employment from a Canadian Employer?"
            value={viewData.have_official_offer_from_can_employeer}
          />
          <LabelAndValue
            label="Have a certificate of qualification in a trade occupation issued by a province?"
            value={viewData.have_cert_of_qual_in_trade_occup_by_province}
          />
          <LabelAndValue
            label="Do you have a a nomination certificate from a Canadian province (except Quebec)?"
            value={viewData.have_nomination_cert_from_can_province}
          />
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="mt-4 flex justify-between">
        <Link to="../work-experience">
          <Button variant={`outline`}>
            <MoveLeftIcon className="mr-2 w-4 h-4" /> Work Experience
          </Button>
        </Link>
        <Link to="../relations">
          <Button variant={`outline`}>
            Relations in Canada / Australia
            <MoveRightIcon className="ml-2 w-4 h-4" />
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default WorkExpCanAusView;
