import { Checkbox } from "@/components/ui/checkbox";
import { formatDate } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { MoveRightIcon } from "lucide-react";
import { Link } from "react-router-dom";

type User = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_number: string;
  lead_status: string;
  lead_manager: {
    first_name: string;
    last_name: string;
  };
  source: string;
  createdAt: string;
  updatedAt: string;
};

const columnHelper = createColumnHelper<User>();

export const UsersCols = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
    header: "Name",
  }),
  columnHelper.accessor("email", {
    header: "Email",
  }),
  columnHelper.accessor("contact_number", {
    header: "Contact Number",
  }),
  columnHelper.accessor(
    (row) => {
      return row.lead_manager
        ? `${row?.lead_manager?.first_name} ${row?.lead_manager?.last_name}`
        : "";
    },
    {
      header: "Lead Manager",
    }
  ),
  columnHelper.accessor("lead_status", {
    header: "Lead Status",
  }),
  columnHelper.accessor("source", {
    header: "Source",
  }),
  columnHelper.accessor((row) => formatDate(row.createdAt), {
    header: "Received On",
  }),
  columnHelper.accessor((row) => formatDate(row.updatedAt), {
    header: "Updated On",
  }),
  columnHelper.accessor((row) => row._id, {
    header: "Actions",
    cell: (info) => (
      <Link to={`lead/${info.getValue()}`}>
        <MoveRightIcon className="w-5 h-5 text-primary" />
      </Link>
    ),
  }),
];
