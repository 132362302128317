import { UserMenuData } from "@/data/UserMenuData";
import { MoveRight } from "lucide-react";
import { NavLink } from "react-router-dom";

const UserMenu = () => {
  return (
    <div className="flex flex-col justify-start bg-white p-3 rounded-md">
      {UserMenuData.map((menu_item) => {
        return (
          <NavLink to={menu_item.menu_link || "#"} key={menu_item.menu_link}>
            {({ isActive }) => (
              <div
                className={`flex gap-2 items-center justify-between text-gray-500 rounded-md p-3 border-b ${
                  isActive ? "bg-primary text-white" : "hover:bg-slate-100"
                }`}
              >
                {menu_item.menu_title} <MoveRight className="w-4 h-4" />
              </div>
            )}
          </NavLink>
        );
      })}
    </div>
  );
};

export default UserMenu;
