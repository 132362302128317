import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  DESTINATION_COUNTRIES,
  MARITAL_STATUS,
  VISA_TYPES,
} from "@/data/assessment.data";
import { Input } from "@/components/ui/input";
import { InputDateFormat, serverErrorMessage } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PersonalDetailsFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toast } from "react-toastify";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const PersonalDetailsForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const location = useLocation();
  const preFormData = location.state?.preFormData;
  const { userId } = useParams();
  const form = useForm<z.infer<typeof PersonalDetailsFormSchema>>({
    resolver: zodResolver(PersonalDetailsFormSchema),
    defaultValues: {
      no_of_children: 0,
    },
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof PersonalDetailsFormSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(
          `user/${userId}/personal-details`,
          formData
        );
      } else {
        return await axiosInstance.post(
          `user/${userId}/personal-details`,
          formData
        );
      }
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, "personal-details"],
      });
      if (preFormData) {
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof PersonalDetailsFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      const { dob, spouse_dob, passport_expire_date, ...rest } = preFormData;
      form.reset({
        dob: InputDateFormat(dob),
        spouse_dob: InputDateFormat(spouse_dob),
        passport_expire_date: InputDateFormat(passport_expire_date),
        ...rest,
      });
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle>
              {preFormData ? "Edit" : "Add"} Personal Details
            </CardTitle>
            <CardDescription>User Personal Details</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="destination_country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Destination Country</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Destination Country" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {DESTINATION_COUNTRIES.map((item, index: number) => {
                          return (
                            <SelectItem value={item} key={index}>
                              {item}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="visa_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Visa Type</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Visa Type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {VISA_TYPES.map((item, index: number) => {
                          return (
                            <SelectItem value={item} key={index}>
                              {item}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="gender"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Gender</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Male">Male</SelectItem>
                        <SelectItem value="Female">Female</SelectItem>
                        <SelectItem value="Others">Others</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="marital_status"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Marital Status</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Marital Status" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {MARITAL_STATUS.map((item, index: number) => {
                          return (
                            <SelectItem value={item} key={index}>
                              {item}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="no_of_children"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Number of Children (If Married)</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Number of Children"
                        type="number"
                        inputMode="numeric"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dob"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Date of Birth</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="spouse_dob"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Spouse Date of Birth</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="passport_number"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Passport Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Passport Number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="passport_expire_date"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Passport Expiration Date</FormLabel>
                    <FormControl>
                      <Input type="date" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="alt_contact_number"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Alternate Contact Number</FormLabel>
                    <FormControl>
                      <Input
                        type="tel"
                        placeholder="Alternate Contact Number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="alt_email"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Alternate Email</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Alternate Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button
              type="submit"
              className="bg-primary hover:bg-primary/80 my-4"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default PersonalDetailsForm;
