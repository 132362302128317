import ReminderForm from "@/components/Forms/Lead/ReminderForm";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { formatDate } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { PencilIcon, PlusIcon } from "lucide-react";
import { useParams } from "react-router-dom";

type ReminderProps = {
  _id: string;
  title: string;
  followup: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

const UserReminders = () => {
  return (
    <Card>
      <CardHeader>
        <div className="flex items-top justify-between">
          <div>
            <CardTitle>Reminders</CardTitle>
            <CardDescription>Manage all user reminders here</CardDescription>
          </div>
          <Popover>
            <PopoverTrigger asChild>
              <Button className="bg-primary">
                <PlusIcon className="mr-2 w-4 h-4" />
                Add Reminder
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <ReminderForm mode="CREATE" />
            </PopoverContent>
          </Popover>
        </div>
      </CardHeader>
      <CardContent>
        <RemindersContent />
      </CardContent>
    </Card>
  );
};

const RemindersContent = () => {
  const axiosInstance = useAxiosAuthInstance();
  let { userId } = useParams();
  const { data, isPending, isError, error } = useQuery<ReminderProps[]>({
    queryKey: ["userReminders", userId],
    queryFn: async () =>
      await axiosInstance
        .get(`/user/${userId}/reminders`)
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  const ReminderDateFormat = (value: string) => {
    if (!value) return null;
    return format(new Date(value), "MMM d, yyyy, hh:mm a");
  };

  if (isPending) {
    return <p>Fetching Data...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  if (!data.length) {
    return <p className="text-center">No Reminders</p>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Reminder Title</TableHead>
          <TableHead>Followup Time</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Created At</TableHead>
          <TableHead>Updated At</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((item) => {
          return (
            <TableRow>
              <TableCell className="font-medium">{item.title}</TableCell>
              <TableCell>{ReminderDateFormat(item.followup)}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{formatDate(item.createdAt)}</TableCell>
              <TableCell>{formatDate(item.updatedAt)}</TableCell>
              <TableCell className="text-right">
                <Popover>
                  <PopoverTrigger>
                    <Button variant={`outline`} className="text-primary">
                      <PencilIcon className="w-4 h-4" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <ReminderForm mode="EDIT" recordId={item._id} />
                  </PopoverContent>
                </Popover>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default UserReminders;
