import "@/App.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useToken from "./hooks/useToken";
// import { decodeJwtToken } from "./lib/utils";

function App() {
  const { authToken } = useToken();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!authToken) {
  //     navigate("/login");
  //   } else {
  //     console.log("token", decodeJwtToken(authToken));
  //     navigate("/dashboard");
  //   }
  // }, [authToken, navigate]);

  useEffect(() => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  return (
    <>
      <ToastContainer />
      <Outlet />
    </>
  );
}

export default App;
