import { Button } from "@/components/ui/button";
import { LabelAndValue } from "../DefaultComponents";
import { parseISO, format } from "date-fns";
import { MoveRightIcon, Pencil, Trash } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CardContent,
  Card,
  CardFooter,
  CardHeader,
  CardDescription,
  CardTitle,
} from "@/components/ui/card";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TView = {
  viewData: {
    destination_country: string;
    visa_type: string;
    gender: string;
    marital_status: string;
    no_of_children: number;
    dob: string;
    alt_contact_number: string;
    alt_email: string;
    spouse_dob: string;
    passport_number: string;
    passport_expire_date: string;
  };
};

const PersonalDetailsView = ({ viewData }: TView) => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ userId }: { userId: string | undefined }) => {
      return axiosInstance.delete(`user/${userId}/personal-details`);
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, "personal-details"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutate({ userId });
  };

  const parseISOtoDate = (value: string) => {
    if (!value) return null;
    return format(parseISO(value), "dd/MM/yyyy");
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-xl">Personal Details</CardTitle>
            <CardDescription>User Personal Details</CardDescription>
          </div>
          <div className="flex flex-row gap-4">
            <Link to="edit" state={{ preFormData: viewData }}>
              <Button variant={`outline`}>
                <Pencil className="mr-2 w-4 h-4" /> Edit
              </Button>
            </Link>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardHeader>
      <Separator />
      <CardContent>
        <div className="flex flex-col mt-4">
          <LabelAndValue
            label="Destination Country"
            value={viewData.destination_country}
          />
          <LabelAndValue label="Visa Type" value={viewData.visa_type} />
          <LabelAndValue label="Gender" value={viewData.gender} />
          <LabelAndValue
            label="Marital Status"
            value={viewData.marital_status}
          />
          <LabelAndValue
            label="No. of Children"
            value={viewData.no_of_children}
          />
          <LabelAndValue
            label="Date of Birth"
            value={parseISOtoDate(viewData.dob)}
          />
          <LabelAndValue
            label="Alternate Contact Number"
            value={viewData.alt_contact_number}
          />
          <LabelAndValue label="Alternate Enail" value={viewData.alt_email} />
          <LabelAndValue
            label="Spouse DOB"
            value={parseISOtoDate(viewData.spouse_dob)}
          />
          <LabelAndValue
            label="Passport Number"
            value={viewData.passport_number}
          />
          <LabelAndValue
            label="Passport Exp. Date"
            value={parseISOtoDate(viewData.passport_expire_date)}
          />
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="mt-4 flex justify-end">
        <Link to="../address">
          <Button variant={`outline`}>
            Address Details
            <MoveRightIcon className="ml-2 w-4 h-4" />
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default PersonalDetailsView;
