import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface UsersPageBulkAssignLeadsState {
  selected_employee_id: string;
  selected_lead_status: string;
  selected_lead_ids: string[];
}

const defaultState: UsersPageBulkAssignLeadsState = {
  selected_employee_id: "",
  selected_lead_status: "",
  selected_lead_ids: [],
};

interface UsersPageBulkAssignLeadsStore {
  data: UsersPageBulkAssignLeadsState;
  set_selected_employee: (employeeId: string) => void;
  set_lead_status: (lead_status: string) => void;
  set_lead_ids: (ids: string[]) => void;
}

const useUsersPageBulkAssignLeads = create<UsersPageBulkAssignLeadsStore>()(
  devtools((set) => ({
    data: defaultState,
    set_selected_employee: (employeeId) =>
      set((state) => ({
        data: { ...state.data, selected_employee_id: employeeId },
      })),
    set_lead_status: (lead_status) =>
      set((state) => ({
        data: { ...state.data, lead_status: lead_status },
      })),
    set_lead_ids: (ids) =>
      set((state) => ({
        data: { ...state.data, selected_lead_ids: ids },
      })),
  }))
);

export default useUsersPageBulkAssignLeads;
