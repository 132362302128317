import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useTokenStore } from "@/lib/store";

export const useAxiosAuthInstance = () => {
  const queryClient = useQueryClient();
  const { authToken, deleteToken } = useTokenStore();
  const config = {
    baseURL: import.meta.env.VITE_ALLOWED_API,
    timeout: 10000,
  };
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(
    (config) => {
      const cookieToken = Cookies.get("authToken");
      if (authToken !== cookieToken) {
        queryClient.cancelQueries();
        deleteToken();
      }
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};
