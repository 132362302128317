import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { RoleSchema } from "@/components/FormInterfaces/Employees";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { serverErrorMessage } from "@/lib/utils";
import { toast } from "react-toastify";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const RoleForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { empId } = useParams();

  const form = useForm<z.infer<typeof RoleSchema>>({
    resolver: zodResolver(RoleSchema),
  });

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof RoleSchema>) =>
      await axiosInstance.post(
        `admin/employees/${empId}/assign-role`,
        formData
      ),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["employee", empId],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit = (formData: z.infer<typeof RoleSchema>) => {
    mutation.mutateAsync(formData);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-row items-end gap-4"
      >
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Employee Role</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger className="w-[250px]">
                    <SelectValue placeholder="Select Role" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="admin">Admin</SelectItem>
                  <SelectItem value="employee">Employee</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">Add</Button>
      </form>
    </Form>
  );
};

export default RoleForm;
