import { Button } from "@/components/ui/button";
import { formatDate } from "@/lib/utils";
import {
  createColumnHelper,
  Column,
  SortDirection,
} from "@tanstack/react-table";
import { ArrowUp, ArrowDown, ArrowUpDown } from "lucide-react";

export type TEleLead = {
  name: string;
  email: string;
  contact_number: string;
  age: string;
  education: string;
  experience: string;
  english_skills: string;
  createdAt: string;
};

type TSortingComponent = {
  title: string;
  isSorted: false | SortDirection;
  column: Column<TEleLead, string>;
};

const SortingComponent = ({ title, isSorted, column }: TSortingComponent) => {
  return (
    <div className="flex items-center gap-2">
      {title}
      <Button
        variant={isSorted ? "blue" : "outline"}
        size="sm"
        onClick={() => column.toggleSorting()}
      >
        {isSorted === "asc" ? (
          <ArrowDown className="h-4 w-4" />
        ) : isSorted === "desc" ? (
          <ArrowUp className="h-4 w-4" />
        ) : (
          <ArrowUpDown className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

const columnHelper = createColumnHelper<TEleLead>();

export const EliLeadsCols = [
  // columnHelper.display({
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //     />
  //   ),
  //   cell: (props) => (
  //     <Checkbox
  //       checked={props.row.getIsSelected()}
  //       onCheckedChange={(value) => props.row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //     />
  //   ),
  // }),
  columnHelper.accessor("name", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Name" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("email", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Email" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("contact_number", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Contact" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("age", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Age" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("education", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="Education"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("experience", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="Experience"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("english_skills", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="English Skills"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdAt", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="Received On"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    cell: (props) => formatDate(props.getValue()),
  }),
];
