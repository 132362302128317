import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { usePageFilters } from "@/lib/store";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const FormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
});

type StateProps = {
  _id: string;
  first_name: string;
  last_name: string;
};

export function LeadManagerFilter({ pageName }: { pageName: string }) {
  const axiosInstance = useAxiosAuthInstance();
  const { setEmployeesFilters, pageFilters } = usePageFilters();
  const ContextPageFilters = pageFilters[pageName];
  const [LeadManagers, setLeadManagers] = useState<StateProps[]>([]);
  const { data } = useQuery({
    queryKey: ["lead_managers"],
    queryFn: async () =>
      await axiosInstance
        .get(`/admin/lead-managers`)
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items:
        ContextPageFilters && ContextPageFilters.employeesFilters
          ? ContextPageFilters.employeesFilters
          : [],
    },
  });

  const employeesFilters = useWatch({
    control: form.control,
    name: "items",
  });

  useEffect(() => {
    if (data && Array.isArray(data) && data.length) {
      setLeadManagers(() => [...data]);
    }
  }, [data]);

  useEffect(() => {
    if (employeesFilters) {
      setEmployeesFilters(pageName, employeesFilters);
    }
  }, [employeesFilters]);

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="items"
        render={() => (
          <FormItem>
            <div className="mb-4">
              <FormLabel className="text-base">Employees</FormLabel>
              <FormDescription>Select Employees to Filter Data</FormDescription>
            </div>
            {LeadManagers.map((item) => (
              <FormField
                key={item._id}
                control={form.control}
                name="items"
                render={({ field }) => {
                  return (
                    <FormItem
                      key={item._id}
                      className="flex flex-row items-start space-x-3 space-y-0"
                    >
                      <FormControl>
                        <Checkbox
                          checked={field.value?.includes(item._id)}
                          onCheckedChange={(checked) => {
                            return checked
                              ? field.onChange([...field.value, item._id])
                              : field.onChange(
                                  field.value?.filter(
                                    (value) => value !== item._id
                                  )
                                );
                          }}
                        />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {item.first_name + " " + item.last_name}
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
            ))}
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
}
