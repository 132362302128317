import {
  BellPlusIcon,
  DownloadIcon,
  FileClockIcon,
  GlobeIcon,
  IndianRupeeIcon,
  LayoutDashboardIcon,
  ListChecks,
  NetworkIcon,
  PhoneOutgoingIcon,
  Settings2Icon,
  User2Icon,
  UserCheck,
} from "lucide-react";

const webMenu = [
  {
    menu_title: "Web Leads",
    menu_link: "/web-leads",
    icon: <GlobeIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Eligibility Leads",
    menu_link: "/eligibility-leads",
    icon: <ListChecks className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Import Leads",
    menu_link: "/channel-leads",
    icon: <NetworkIcon className="w-4 h-4 mr-2" />,
  },
];

const crmMenu = [
  {
    menu_title: "Users",
    menu_link: "/crm-leads",
    icon: <UserCheck className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Calls",
    menu_link: "/calls",
    icon: <PhoneOutgoingIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Reminders",
    menu_link: "/reminders",
    icon: <BellPlusIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Payments",
    menu_link: "/payments",
    icon: <IndianRupeeIcon className="w-4 h-4 mr-2" />,
  },
];

const empManageMenu = [
  {
    menu_title: "Employees",
    menu_link: "/employees",

    icon: <User2Icon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Time Tracker",
    menu_link: "/time-tracker",
    icon: <FileClockIcon className="w-4 h-4 mr-2" />,
  },
];

const dashboardMenu = [
  {
    menu_title: "Dashboard",
    menu_link: "/dashboard",
    icon: <LayoutDashboardIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Reports",
    menu_link: "/reports",
    icon: <DownloadIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Settings",
    menu_link: "/settings",
    icon: <Settings2Icon className="w-4 h-4 mr-2" />,
  },
];

export { webMenu, crmMenu, empManageMenu, dashboardMenu };
