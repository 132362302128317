import { useTokenStore } from "@/lib/store";
import { BellIcon, LogOutIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { useEffect } from "react";
import { useDecodeToken } from "@/hooks/useDecodeToken";

const NotificationsBar = () => {
  const { deleteToken, authToken } = useTokenStore();
  const navigate = useNavigate();
  const decodedToken = useDecodeToken();

  const handleLogout = () => {
    deleteToken();
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken]);

  return (
    <div className="bg-white mb-8 p-3 mx-8 shadow-md m-3 rounded-md">
      <div className="flex justify-between items-center gap-8">
        <div className="flex flex-col">
          <b className="text-primary">{decodedToken?.name}</b>
          <span className="text-sm text-gray-500">
            {decodedToken?.username}
          </span>
        </div>
        <div className="flex items-center gap-5">
          <Button variant={`outline`}>
            <BellIcon className="text-primary w-4 h-4" />
          </Button>
          <Button variant={`outline`} onClick={() => handleLogout()}>
            Signout <LogOutIcon className="text-red-500 w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsBar;
