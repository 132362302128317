import EducationsView from "@/components/FormViews/Assessment/EducationsView";
import LanguagesView from "@/components/FormViews/Assessment/LanguagesView";
import WorkExpsView from "@/components/FormViews/Assessment/WorkExpsView";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

const CrmArrayTypeFormsWrapper = () => {
  const axiosInstance = useAxiosAuthInstance();
  const location = useLocation();
  const { type, userId } = useParams();
  let resource = location.pathname.split("/").pop();

  const { data, isPending, isError, error } = useQuery({
    queryKey: [userId, type, resource],
    queryFn: async () =>
      await axiosInstance.get(`/user/${userId}/${resource}/${type}`),
  });

  if (isPending) {
    return <p>Checking Resource...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  return (
    <>
      {resource === "educations" && <EducationsView viewData={data?.data} />}
      {resource === "languages" && <LanguagesView viewData={data?.data} />}
      {resource === "work-experience" && <WorkExpsView viewData={data?.data} />}
    </>
  );
};

export default CrmArrayTypeFormsWrapper;
