import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const DeleteReminder = ({ recordId }: { recordId: string | undefined }) => {
  const axiosInstance = useAxiosAuthInstance();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (recordId: string | undefined) =>
      await axiosInstance.delete(`/admin/reminders/${recordId}`),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["adminReminders"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const handleDelete = ({ recordId }: { recordId: string | undefined }) => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (confirm_response) {
      mutation.mutateAsync(recordId);
    }
  };

  return (
    <Button
      variant={`outline`}
      className="text-red-500 hover:bg-red-500 hover:text-white"
      onClick={() => handleDelete({ recordId })}
    >
      <TrashIcon className="w-4 h-4 mr-2" />
      Delete
    </Button>
  );
};

export default DeleteReminder;
