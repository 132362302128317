import { LeadStatusSchema } from "@/components/FormInterfaces/LeadSchemas";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormMessage,
  FormItem,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LEAD_STATUSES } from "@/data/assessment.data";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

const LeadStatusForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const form = useForm<z.infer<typeof LeadStatusSchema>>({
    resolver: zodResolver(LeadStatusSchema),
    defaultValues: {
      lead_status: "",
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof LeadStatusSchema>) =>
      await axiosInstance.patch(`user/${userId}/lead-status`, formData),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["lead", userId],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof LeadStatusSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-row items-end gap-4"
      >
        <FormField
          control={form.control}
          name="lead_status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Lead Status</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Lead Status" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {LEAD_STATUSES.map((item, index: number) => {
                    return (
                      <SelectItem value={item} key={index}>
                        {item}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <Button className="bg-primary hover:bg-primary/80 w-full">
            Update
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LeadStatusForm;
