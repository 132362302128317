import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { useParams } from "react-router-dom";
import {
  WORK_EXP_DURATION,
  WORK_EXP_EMP_TYPE,
  WORK_EXP_JOB_LOCATIONS,
} from "@/data/assessment.data";
import { WorkExpFormSchema } from "@/components/FormInterfaces/Assessment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import useStore from "@/lib/store";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TForm = {
  formData: z.infer<typeof WorkExpFormSchema>;
  userId: string | undefined;
};

const WorkExpForm = ({
  preFormData,
  closeDialog,
}: {
  preFormData?: z.infer<typeof WorkExpFormSchema> & { _id: string };
  closeDialog?: (value: boolean) => void;
}) => {
  const axiosInstance = useAxiosAuthInstance();
  const recordId = preFormData && preFormData._id;
  const { changeCreateDialogState } = useStore();
  const { userId, type } = useParams();
  const form = useForm<z.infer<typeof WorkExpFormSchema>>({
    resolver: zodResolver(WorkExpFormSchema),
    defaultValues: {
      for: type,
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ formData, userId }: TForm) => {
      if (preFormData) {
        return await axiosInstance.patch(
          `user/${userId}/work-experience/${recordId}/${type}`,
          formData
        );
      } else {
        return await axiosInstance.post(
          `user/${userId}/work-experience/${type}`,
          formData
        );
      }
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      if (preFormData && closeDialog) {
        closeDialog(false);
      } else {
        changeCreateDialogState(false);
      }
      queryClient.invalidateQueries({
        queryKey: [userId, type, "work-experience"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof WorkExpFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync({ formData, userId });
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 gap-8">
          <FormField
            control={form.control}
            name="job_title"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Job Title</FormLabel>
                <FormControl>
                  <Input placeholder="Job Title" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="company_name"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input placeholder="Company Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="duration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Duration</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WORK_EXP_DURATION.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job Location</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WORK_EXP_JOB_LOCATIONS.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="province"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Province</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Province" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="currently_working"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Are you Currently Working in this job?</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    className="flex flex-row items-center gap-4"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="Yes" />
                      </FormControl>
                      <FormLabel className="font-normal">Yes</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="No" />
                      </FormControl>
                      <FormLabel className="font-normal">No</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="job_qualified_for_social_security"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Is this Job Qualified for Social Security?
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    className="flex flex-row items-center gap-4"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="Yes" />
                      </FormControl>
                      <FormLabel className="font-normal">Yes</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="No" />
                      </FormControl>
                      <FormLabel className="font-normal">No</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="noc"
            render={({ field }) => (
              <FormItem>
                <FormLabel>NOC?</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    className="flex flex-row items-center gap-4"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="Yes" />
                      </FormControl>
                      <FormLabel className="font-normal">Yes</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="No" />
                      </FormControl>
                      <FormLabel className="font-normal">No</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="type_of_employement"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Employement Type</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="w-[250px]">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {WORK_EXP_EMP_TYPE.map((item, index: number) => {
                      return (
                        <SelectItem value={item} key={index}>
                          {item}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="main_duties"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Main Duties</FormLabel>
                <FormControl>
                  <Input placeholder="Main Duties" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button type="submit" className="my-4 bg-primary hover:bg-primary/80">
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default WorkExpForm;
