import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPasswordSchema = z.object({
  username: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
});

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxiosAuthInstance();
  const form = useForm<z.infer<typeof ForgotPasswordSchema>>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      username: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async ({
      formData,
    }: {
      formData: z.infer<typeof ForgotPasswordSchema>;
    }) => await axiosInstance.post("/send-forgot-password-token", formData),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      //navigate("/login");
      navigate("/success?success=true");
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof ForgotPasswordSchema>> = (
    formData
  ) => {
    mutation.mutateAsync({ formData });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter Email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full bg-primary hover:bg-primary/80">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default ForgotPasswordForm;
