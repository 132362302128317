import { Outlet } from "react-router-dom";

import AssessmentLayout from "@/layouts/AssessmentLayout";
import CrmFormsWrapper from "@/layouts/CrmFormsWrapper";
import AddressForm from "@/components/Forms/Assessment/AddressForm";
import PersonalDetailsForm from "@/components/Forms/Assessment/PersonalDetailsForm";
import WorkExpCanAusForm from "@/components/Forms/Assessment/WorkExpCanAusForm";
import RelationsForm from "@/components/Forms/Assessment/RelationsForm";
import CrmArrayTypeFormsWrapper from "@/layouts/CrmArrayTypeFromWrapper";
import LeadLayout from "@/layouts/LeadLayout";
import UserReminders from "@/pages/users/UserReminders";
import UserFiles from "@/pages/users/UserFiles";
import UserPayments from "@/pages/users/UserPayments";
import UserAssessmentStatus from "@/pages/users/UserAssessmentStatus";
import UserSupportTickets from "@/pages/users/UserSupportTickets";
import NewUsersPage from "@/pages/users/NewUsersPage";
// import TestUsers from "@/pages/users/TestUsers";

const CrmLeadsRoutes = [
  {
    path: "crm-leads",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <NewUsersPage />,
      },
      {
        path: "lead/:userId",
        element: <LeadLayout />,
        children: [
          {
            path: "personal-details",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CrmFormsWrapper />,
              },
              {
                path: "edit",
                element: <PersonalDetailsForm />,
              },
            ],
          },
          {
            path: "address",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <CrmFormsWrapper />,
              },
              {
                path: "edit",
                element: <AddressForm />,
              },
            ],
          },
          {
            path: "assessment/:type",
            element: <AssessmentLayout />,
            children: [
              {
                path: "educations",
                element: <CrmArrayTypeFormsWrapper />,
              },
              {
                path: "languages",
                element: <CrmArrayTypeFormsWrapper />,
              },
              {
                path: "work-experience",
                element: <CrmArrayTypeFormsWrapper />,
              },
              {
                path: "work-in-can-aus",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <CrmFormsWrapper />,
                  },
                  {
                    path: "edit",
                    element: <WorkExpCanAusForm />,
                  },
                ],
              },
              {
                path: "relations",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <CrmFormsWrapper />,
                  },
                  {
                    path: "edit",
                    element: <RelationsForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "reminders",
            element: <UserReminders />,
          },
          {
            path: "files",
            element: <UserFiles />,
          },
          {
            path: "payments",
            element: <UserPayments />,
          },
          {
            path: "assessment-status",
            element: <UserAssessmentStatus />,
          },
          {
            path: "support-tickets",
            element: <UserSupportTickets />,
          },
        ],
      },
    ],
  },
];

export default CrmLeadsRoutes;
