import { ColumnDef } from "@tanstack/react-table";
import { MoveRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { formatDate } from "@/lib/utils";

export type EmpColsProps = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_number: string;
  roles: string[];
};

export const EmpCols: ColumnDef<EmpColsProps>[] = [
  {
    accessorKey: "first_name",
    header: "First Name",
  },
  {
    accessorKey: "last_name",
    header: "Last Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "contact_number",
    header: "Contact",
  },
  {
    accessorKey: "roles",
    header: "Roles",
    cell: ({ row }) => {
      const rolesArray = row.original.roles;
      return rolesArray.map((role) => (
        <span className="p-2 bg-slate-100 m-2 text-primary border rounded-md">
          {role}
        </span>
      ));
    },
  },
  {
    accessorKey: "createdAt",
    header: "Created On",
    cell: ({ row }) => {
      return formatDate(row.getValue("createdAt"));
    },
  },
  {
    accessorKey: "updatedAt",
    header: "Last Updated",
    cell: ({ row }) => {
      return formatDate(row.getValue("updatedAt"));
    },
  },
  {
    header: "Actions",
    accessorKey: "_id",
    cell: ({ row }) => {
      return (
        <Link to={`manage/${row.getValue("_id")}`}>
          <MoveRightIcon className="w-5 h-5 text-primary" />
        </Link>
      );
    },
  },
];
