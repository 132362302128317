import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EmployeeSchema } from "@/components/FormInterfaces/Employees";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TForm = {
  formData: z.infer<typeof EmployeeSchema>;
};

const EmployeeForm = ({
  preFormData,
  closeDialog,
}: {
  preFormData?: z.infer<typeof EmployeeSchema> & { _id: string };
  closeDialog?: (value: boolean) => void;
}) => {
  const axiosInstance = useAxiosAuthInstance();
  const form = useForm<z.infer<typeof EmployeeSchema>>({
    resolver: zodResolver(EmployeeSchema),
  });
  const empId = preFormData?._id;
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ formData }: TForm) => {
      if (preFormData) {
        return await axiosInstance.patch(`admin/employees/${empId}`, formData);
      } else {
        return await axiosInstance.post(`admin/employees`, formData);
      }
    },
    onSuccess: (res) => {
      if (closeDialog) {
        closeDialog(false);
      }
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof EmployeeSchema>> = (
    formData
  ) => {
    console.log("formdata", EmployeeSchema.parse(formData));
    mutation.mutateAsync({ formData });
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-5"
      >
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input placeholder="First Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input placeholder="Last Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="contact_number"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Contact Number</FormLabel>
              <FormControl>
                <Input placeholder="Contact Number" type="tel" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="gender"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Gender</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select Gender" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Male">Male</SelectItem>
                  <SelectItem value="Female">Female</SelectItem>
                  <SelectItem value="Others">Others</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="bg-primary hover:bg-primary/80" type="submit">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default EmployeeForm;
