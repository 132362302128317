export type UserMenuProps = { menu_title: string; menu_link: string };

export const UserMenuData: UserMenuProps[] = [
  {
    menu_title: "Personal Details",
    menu_link: "personal-details",
  },
  {
    menu_title: "Address Info.",
    menu_link: "address",
  },
  {
    menu_title: "Self Assessment",
    menu_link: "assessment/self",
  },
  {
    menu_title: "Spouse Assessment",
    menu_link: "assessment/spouse",
  },
  {
    menu_title: "Reminders",
    menu_link: "reminders",
  },
  {
    menu_title: "Files",
    menu_link: "files",
  },
  {
    menu_title: "Payments",
    menu_link: "payments",
  },
  {
    menu_title: "Assessment Status",
    menu_link: "assessment-status",
  },
  {
    menu_title: "Support Tickets",
    menu_link: "support-tickets",
  },
];
