export const DESTINATION_COUNTRIES: string[] = [
  "Canada",
  "Australia",
  "Newzealand",
  "Hong Kong",
  "USA",
];

export const VISA_TYPES: string[] = [
  "PR Visa",
  "Visit Visa",
  "Work Visa",
  "Education Visa",
  "Travel Visa",
];

export const MARITAL_STATUS: string[] = [
  "Married",
  "Never Married",
  "Widowed",
  "Legally Separated",
  "Annulled Marriage",
  "Divorced",
  "Common Law",
];

export const HIGHEST_EDU_LEVEL: string[] = [
  "Ph.D.",
  "Master's Degree",
  "2 or more Bachelor's Degrees",
  "Bachelor's Degree (4 years)",
  "Bachelor's Degree (3 years)",
  "Bachelor's Degree (2 years)",
  "Bachelor's Degree (1 years)",
  "Diploma, ITI or Apprentice (3 years)",
  "Diploma, ITI or Apprentice (2 years)",
  "Diploma, ITI or Apprentice (1 years)",
  "High School Diploma",
  "Below High School Diploma",
];

export const LEAD_STATUSES: string[] = [
  "New Lead",
  "Not Reachable",
  "Wrong Number",
  "Call Disconnected",
  "Call Not Answering",
  "Closed Lost",
  "Closed Junk",
  "Interested Cold",
  "Interested Warm",
  "Interested Hot",
  "Registered (IER)",
  "Full Paid",
  "Contact in Future",
];

export const LANGUAGE_SKILL = [
  "Pearson Test of English (PTE)",
  "IELTS",
  "French",
  "CELPIP Test",
];

export const LANGUAGE_SKILL_VALUES = [
  1, 2, 3, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
].map(String);

export const WORK_EXP_DURATION = [
  "Below 3 Months",
  "3 to 6 Months",
  "6 to 9 Months",
  "9 Months to 1 Year",
  "1 to 2 Years",
  "2 to 3 Years",
  "3 to 4 Years",
  "4 to 5 Years",
  "5 to 6 Years",
];

export const WORK_EXP_JOB_LOCATIONS = ["India", "Outside India"];
export const WORK_EXP_EMP_TYPE = ["Government", "Private", "Others"];

export const RELATIONS = [
  "Mother or Father",
  "Daughter or Son",
  "Sister or Brother",
  "Niece or Nephew",
  "Grandmother or Grandfather",
  "Granddaugher or Grandson",
  "Aunt or Uncle",
  "Spouse or common-law partner",
];

export const RELATIVE_AGE = ["Younger than 18 years", "18 years or over"];
export const RELATIVE_EMP_STATUS = ["Employed", "Self-Employed", "Unemployed"];
export const RELATIVE_FIN_RESPONSIBLE = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(String);

export const WORK_IN_CAN_AUS_YEARS_WORKED = [
  "None",
  "1 Year",
  "2 Years or more",
];

export const WORK_IN_CAN_AUS_EMP_LEFT = [
  "Less than a year ago",
  "More than a year ago",
];
