import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  WORK_IN_CAN_AUS_YEARS_WORKED,
  WORK_IN_CAN_AUS_EMP_LEFT,
} from "@/data/assessment.data";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { WorkExpCanAusFormSchema } from "@/components/FormInterfaces/Assessment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const WorkExpCanAusForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const location = useLocation();
  const preFormData = location.state?.preFormData;
  const { userId } = useParams();
  const form = useForm<z.infer<typeof WorkExpCanAusFormSchema>>({
    resolver: zodResolver(WorkExpCanAusFormSchema),
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof WorkExpCanAusFormSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(
          `user/${userId}/work-in-can-aus`,
          formData
        );
      } else {
        return await axiosInstance.post(
          `user/${userId}/work-in-can-aus`,
          formData
        );
      }
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, "work-in-can-aus"],
      });
      if (preFormData) {
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof WorkExpCanAusFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      console.log("preformdata", preFormData);
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle>
              {preFormData ? "Edit" : "Add"} Work Experience in Canada or
              Australia
            </CardTitle>
            <CardDescription>
              Enter Applicant Work Experience Details for Assessment.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 gap-8">
              <FormField
                control={form.control}
                name="have_been_in_can_or_aus_as_temporary_foreign_worker"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Have you been in Canada/Australia as a temporary foreign
                      worker?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="flex flex-row items-center gap-4"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Yes" />
                          </FormControl>
                          <FormLabel className="font-normal">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="No" />
                          </FormControl>
                          <FormLabel className="font-normal">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="years_worked_fulltime_in_can_or_aus"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      How many years have you worked full-time in this position
                      in Canada/Australia?
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-[250px]">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {WORK_IN_CAN_AUS_YEARS_WORKED.map(
                          (item, index: number) => {
                            return (
                              <SelectItem value={item} key={index}>
                                {item}
                              </SelectItem>
                            );
                          }
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="currently_employeed_in_can_or_aus"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Currently employed in Canada/Australia?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="flex flex-row items-center gap-4"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Yes" />
                          </FormControl>
                          <FormLabel className="font-normal">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="No" />
                          </FormControl>
                          <FormLabel className="font-normal">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="left_employement_in_can_or_aus"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      When did you leave your employment in Canada/Australia?
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-[250px]">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {WORK_IN_CAN_AUS_EMP_LEFT.map((item, index: number) => {
                          return (
                            <SelectItem value={item} key={index}>
                              {item}
                            </SelectItem>
                          );
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="have_official_offer_from_can_employeer"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Have an Ofiicial offer of Employment from a Canadian
                      Employer?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="flex flex-row items-center gap-4"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Yes" />
                          </FormControl>
                          <FormLabel className="font-normal">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="No" />
                          </FormControl>
                          <FormLabel className="font-normal">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="have_cert_of_qual_in_trade_occup_by_province"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Have a certificate of qualification in a trade occupation
                      issued by a province?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="flex flex-row items-center gap-4"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Yes" />
                          </FormControl>
                          <FormLabel className="font-normal">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="No" />
                          </FormControl>
                          <FormLabel className="font-normal">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="have_nomination_cert_from_can_province"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Do you have a a nomination certificate from a Canadian
                      province (except Quebec)?
                    </FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        value={field.value}
                        className="flex flex-row items-center gap-4"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Yes" />
                          </FormControl>
                          <FormLabel className="font-normal">Yes</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="No" />
                          </FormControl>
                          <FormLabel className="font-normal">No</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button
              type="submit"
              className="my-4 bg-primary hover:bg-primary/80"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default WorkExpCanAusForm;
