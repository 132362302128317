import { LeadStatusFilter } from "@/components/Forms/Lead/LeadStatusFilter";
import { LeadManagerFilter } from "@/components/Forms/Lead/LeadManagerFilter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { PlusCircleIcon } from "lucide-react";

const UsersPageFilters = () => {
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={`outline`} className="hover:text-primary">
            <PlusCircleIcon className="w-4 h-4 mr-2" />
            Filter by Employees
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit">
          <LeadManagerFilter pageName="users" />
        </PopoverContent>
      </Popover>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={`outline`} className="hover:text-primary">
            <PlusCircleIcon className="w-4 h-4 mr-2" />
            Filter by Lead Status
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-fit">
          <LeadStatusFilter pageName="users" />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default UsersPageFilters;
