import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import { useQuery } from "@tanstack/react-query";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type ChartDataProps = {
  _id: string;
  count: number;
};

const StatusWiseUsersReport = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { data, isPending, isError, error } = useQuery<ChartDataProps[]>({
    queryKey: ["statusWiseUsers"],
    queryFn: async () =>
      await axiosInstance
        .get("/admin/reports/users-statuswise")
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  if (isPending) return <p>Fetching Data....</p>;

  if (isError) return <p>{error.message}</p>;

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 30,
            bottom: 50,
            left: 20,
            right: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" angle={-60} fontSize={`14`} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8">
            {/* <LabelList dataKey="_id" position="top" /> */}
            <LabelList dataKey="count" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StatusWiseUsersReport;
