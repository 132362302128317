import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useTokenStore } from "@/lib/store";
import { serverErrorMessage } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

const LoginSchema = z.object({
  username: z.string().email(),
  password: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
});

const LoginForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { setToken } = useTokenStore();
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async ({
      formData,
    }: {
      formData: z.infer<typeof LoginSchema>;
    }) => await axiosInstance.post(`/login`, formData),
    onSuccess: (res) => {
      const accessToken = res?.data?.accessToken;
      accessToken && setToken(accessToken);
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof LoginSchema>> = ({
    ...formData
  }) => {
    mutation.mutateAsync({ formData });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
              <div className="flex justify-end">
                <Link to="/forgot-password" className="underline text-sm">
                  Forgot Password?
                </Link>
              </div>
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full bg-primary hover:bg-primary/80">
          Login
        </Button>
      </form>
    </Form>
  );
};

export default LoginForm;
