import User from "@/pages/users/User";
import UserCallNotes from "@/pages/users/UserCallNotes";
import UserMenu from "@/pages/users/UserMenu";
import { Outlet } from "react-router-dom";

const LeadLayout = () => {
  return (
    <div className="grid xl:grid-cols-4 gap-5">
      <div className="xl:col-span-3">
        <div className="flex flex-col gap-5">
          <User />
          <div className="grid lg:grid-cols-4 gap-5">
            <div className="lg:col-span-1">
              <UserMenu />
            </div>
            <div className="lg:col-span-3">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:col-span-1 flex flex-col gap-5">
        <UserCallNotes />
      </div>
    </div>
  );
};

export default LeadLayout;
