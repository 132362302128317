import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import TitleAndBC from "@/components/Misc/TitleAndBC";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import NewLeadForm from "@/components/Forms/Lead/NewLeadForm";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DebounceInput } from "react-debounce-input";
import UsersPageFilters from "./UsersPageFilters";
import { UsersCols } from "./UsersCols";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { usePageFilters } from "@/lib/store";
import useUsersPageBulkAssignLeads from "@/store/usersPage.store";
import UsersBulkAction from "./UsersBulkAction";
import { ControlledPagination } from "@/components/controlled-pagination";

const NewUsersPage = () => {
  const axiosInstance = useAxiosAuthInstance();
  const columns = useMemo(() => UsersCols, [UsersCols]);
  const { pageFilters } = usePageFilters();
  const userPageDataFilters = pageFilters["users"];

  //Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  //Filters Start
  const [sorting, setSorting] = useState<SortingState>([]);
  const [EmpIds, setEmpIds] = useState<string[]>([]);
  const [Statuses, setStatuses] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { set_lead_ids } = useUsersPageBulkAssignLeads();

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const queryParams = useMemo(
    () => ({
      currentPage: String(pageIndex), // Convert number to string
      pageSize: String(pageSize), // Convert number to string
      sorting: sorting.map(({ id, desc }) => ({ id, desc })),
      empIds: EmpIds,
      statuses: Statuses,
      searchFilter: searchFilter,
    }),
    [pageIndex, pageSize, sorting, EmpIds, Statuses, searchFilter]
  );

  const { data, isPending, isError, error } = useQuery({
    queryKey: [
      "users",
      pageIndex,
      pageSize,
      searchFilter,
      EmpIds,
      Statuses,
      sorting,
    ],
    queryFn: async () => {
      try {
        const response = await axiosInstance.post(
          "/admin/users/paginated",
          queryParams
        );
        return response.data; // Return the data directly
      } catch (err) {
        throw err; // Re-throw the error to be handled by useQuery
      }
    },
    placeholderData: keepPreviousData,
  });

  // const setResetSelectedRows = () => {
  //   return true;
  // };

  useEffect(() => {
    if (searchFilter) {
      // Update pagination state with pageIndex 0 and keep the same pageSize
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
    }

    if (userPageDataFilters) {
      setEmpIds(userPageDataFilters.employeesFilters);
      setStatuses(userPageDataFilters.statusFilters);
    }
  }, [searchFilter, userPageDataFilters]);

  useEffect(() => {
    set_lead_ids(Object.keys(rowSelection));
  }, [rowSelection]);

  const table = useReactTable({
    data: data?.rows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: data?.pageCount || 0,
    state: {
      pagination,
      rowSelection,
      sorting,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
  });

  if (isPending) return <p>Loading...</p>;
  if (isError) return <p>{error.message}</p>;

  return (
    <div className="flex flex-col gap-4">
      <TitleAndBC page_title="Users" />
      <div className="flex justify-between items-center">
        <UsersBulkAction selectedRecords={Object.keys(rowSelection)} />
        <UsersDataFilters
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      </div>
      <div className="card bg-white">
        <div className="card-body">
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() ? "selected" : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="card-actions p-4">
            <ControlledPagination table={table} />
          </div>
        </div>
      </div>
      {/* <DataTable
        columns={columns}
        data={rows}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={pageCount}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      /> */}
    </div>
  );
};

const UsersDataFilters = ({
  searchFilter,
  setSearchFilter,
}: {
  searchFilter: string;
  setSearchFilter: (value: string) => void;
}) => {
  return (
    <div className="flex flex-col xl:flex-row gap-4 ml-auto">
      <DebounceInput
        className="p-2 border"
        debounceTimeout={500}
        placeholder="Search..."
        value={searchFilter ?? ""}
        onChange={(event) => setSearchFilter(String(event.target.value))}
      />
      <UsersPageFilters />

      <Dialog>
        <DialogTrigger asChild>
          <Button className="bg-primary hover:bg-violet-500">
            <Plus className="mr-2 w-4 h-4" /> Add New User
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>New User</DialogTitle>
          </DialogHeader>
          <NewLeadForm />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewUsersPage;
