import { CardContent, Card } from "@/components/ui/card";
import { Pencil, Trash } from "lucide-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { LabelAndValue } from "../DefaultComponents";
import { WorkExpFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import WorkExpForm from "@/components/Forms/Assessment/WorkExpForm";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TSingleRecord = {
  item: z.infer<typeof WorkExpFormSchema> & { _id: string };
};

const WorkExpSingleRecord = ({ item }: TSingleRecord) => {
  const axiosInstance = useAxiosAuthInstance();
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogClose = (newValue: boolean) => {
    setIsOpen(newValue);
  };

  const { type, userId } = useParams();
  const recordId = item._id;
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ userId }: { userId: string | undefined }) =>
      await axiosInstance.delete(
        `user/${userId}/work-experience/${recordId}/${type}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userId, type, "work-experience"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutateAsync({ userId });
    queryClient.invalidateQueries({
      queryKey: [userId, type, "work-experience"],
    });
  };

  return (
    <Card>
      <CardContent className="flex flex-row justify-between mt-5">
        <div>
          <LabelAndValue label="For" value={item.for} />
          <LabelAndValue label="Job Title" value={item.job_title} />
          <LabelAndValue label="Company Name" value={item.company_name} />
          <LabelAndValue label="Location" value={item.location} />
          <LabelAndValue label="Province" value={item.province} />
          <LabelAndValue label="Duration" value={item.duration} />
          <LabelAndValue
            label="Are you Currently Working in this job?"
            value={item.currently_working}
          />
          <LabelAndValue
            label="Is this Job Qualified for Social Security?"
            value={item.job_qualified_for_social_security}
          />
          <LabelAndValue label="NOC" value={item.noc} />
          <LabelAndValue label="Main Duties" value={item.main_duties} />
        </div>
        <div className="w-1/4">
          <div className="flex flex-row justify-end gap-4">
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
              <DialogTrigger>
                <Button variant={`outline`}>
                  <Pencil className="mr-2 w-4 h-4" /> Edit
                </Button>
              </DialogTrigger>
              <DialogContent
                className="max-w-2xl"
                onInteractOutside={(e) => {
                  e.preventDefault();
                }}
              >
                <DialogHeader>
                  <DialogTitle>Education Record</DialogTitle>
                  <Separator />
                </DialogHeader>
                <WorkExpForm
                  preFormData={item}
                  closeDialog={handleDialogClose}
                />
              </DialogContent>
            </Dialog>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WorkExpSingleRecord;
