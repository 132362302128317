import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import {
  RELATIONS,
  RELATIVE_AGE,
  RELATIVE_EMP_STATUS,
  RELATIVE_FIN_RESPONSIBLE,
} from "@/data/assessment.data";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { RelationsFormSchema } from "@/components/FormInterfaces/Assessment";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { serverErrorMessage } from "@/lib/utils";
import { toast } from "react-toastify";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const RelationsForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const location = useLocation();
  const preFormData = location.state?.preFormData;
  const { userId } = useParams();

  const form = useForm<z.infer<typeof RelationsFormSchema>>({
    resolver: zodResolver(RelationsFormSchema),
    defaultValues: {
      relative_wish_to_sponser: "No",
    },
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof RelationsFormSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(`user/${userId}/relations`, formData);
      } else {
        return await axiosInstance.post(`user/${userId}/relations`, formData);
      }
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, "relations"],
      });
      if (preFormData) {
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof RelationsFormSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle>
              {preFormData ? "Edit" : "Add"} Relations in Canada / Australia
            </CardTitle>
            <CardDescription>
              User Relations in Canada / Australia Details
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="full_time_student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Are you currently a full-time student?</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                      className="flex flex-row items-center gap-4"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="Yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="No" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="full_time_student_parents_dependent"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Have you been a full-time student and substantially
                    dependent on your parents for financial support since before
                    the age of 19?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                      className="flex flex-row items-center gap-4"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="Yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="No" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="have_blood_relative_in_can_or_aus"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Do you or, if applicable your accompanying spouse, or
                    common-law partner <br /> have a blood relative living in
                    Canada/Australia who is a citizen or a permanent resident of
                    Canada/Australia?
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                      className="flex flex-row items-center gap-4"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="Yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="No" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="relative_rel_with_you"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Their relationship with you?</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[250px]">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {RELATIONS.map((item, index: number) => {
                        return (
                          <SelectItem value={item} key={index}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="rel_age"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>How old is your relative?</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[250px]">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {RELATIVE_AGE.map((item, index: number) => {
                        return (
                          <SelectItem value={item} key={index}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="rel_emp_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    What is the employment status of your relative?
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[250px]">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {RELATIVE_EMP_STATUS.map((item, index: number) => {
                        return (
                          <SelectItem value={item} key={index}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="no_of_people_rel_fin_resp_household_in_can_or_aus"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    How many people is this relative financially responsible for
                    in his/her household in Canada/Australia?
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-[250px]">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {RELATIVE_FIN_RESPONSIBLE.map((item, index: number) => {
                        return (
                          <SelectItem value={item} key={index}>
                            {item}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="rel_annual_income"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>
                    How much is the annual household income of your relative?
                    This includes the combined annual income of your relative
                    and his/her spouse.
                  </FormLabel>
                  <FormControl>
                    <Input {...field} className="max-w-sm" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="relative_wish_to_sponser"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Does this relative wish to sponsor you? If unsure, please
                    choose No.
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-row items-center gap-4"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="Yes" />
                        </FormControl>
                        <FormLabel className="font-normal">Yes</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="No" />
                        </FormControl>
                        <FormLabel className="font-normal">No</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button
              type="submit"
              className="my-4 bg-primary hover:bg-primary/80"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default RelationsForm;
