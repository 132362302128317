import { LeadManagerFilter } from "@/components/Forms/Lead/LeadManagerFilter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";

const CallsPageFilters = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={`outline`}>
          <ChevronDown className="w-4 h-4 mr-2" />
          Filter by Employees
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit">
        <LeadManagerFilter pageName="calls" />
      </PopoverContent>
    </Popover>
  );
};

export default CallsPageFilters;
