import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { AddressDetailsSchema } from "@/components/FormInterfaces/Assessment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  CardContent,
  CardFooter,
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const AddressForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const location = useLocation();
  const preFormData = location.state?.preFormData;
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof AddressDetailsSchema>>({
    resolver: zodResolver(AddressDetailsSchema),
    defaultValues: {
      country: "India",
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof AddressDetailsSchema>) => {
      if (preFormData) {
        return await axiosInstance.patch(`user/${userId}/address`, formData);
      } else {
        return await axiosInstance.post(`user/${userId}/address`, formData);
      }
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: [userId, "address"],
      });
      if (preFormData) {
        navigate(-1);
      }
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof AddressDetailsSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  useEffect(() => {
    if (preFormData) {
      form.reset(preFormData);
    }
  }, [preFormData]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>
            <CardTitle>
              {preFormData ? "Edit" : "Add"} Address Details
            </CardTitle>
            <CardDescription>User Address Details</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="h_no"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>House No. / Flat No.</FormLabel>
                    <FormControl>
                      <Input placeholder="House No. / Flat No." {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="locality_or_landmark"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Locality or Landmark</FormLabel>
                    <FormControl>
                      <Input placeholder="Locality or Landmark" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input placeholder="City" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="state"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>State</FormLabel>
                    <FormControl>
                      <Input placeholder="State" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="country"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Country</FormLabel>
                    <FormControl>
                      <Input placeholder="Country" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="zip_code"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>ZIP Code</FormLabel>
                    <FormControl>
                      <Input placeholder="ZIP Code" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button className="bg-primary hover:bg-primary/80 my-4">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default AddressForm;
