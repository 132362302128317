import { AddressDetailsSchema } from "@/components/FormInterfaces/Assessment";
import { LabelAndValue } from "../DefaultComponents";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { MoveLeftIcon, Pencil, Trash } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CardContent,
  Card,
  CardFooter,
  CardHeader,
  CardDescription,
  CardTitle,
} from "@/components/ui/card";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TView = {
  viewData: z.infer<typeof AddressDetailsSchema>;
};

const AddressDetailsView = ({ viewData }: TView) => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ userId }: { userId: string | undefined }) => {
      return axiosInstance.delete(`user/${userId}/address`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userId, "address"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutate({ userId });
  };
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-xl">Address Details</CardTitle>
            <CardDescription>User Address Details</CardDescription>
          </div>
          <div className="flex flex-row gap-4">
            <Link to="edit" state={{ preFormData: viewData }}>
              <Button
                variant={`outline`}
                className="text-primary border-primary"
              >
                <Pencil className="mr-2 w-4 h-4" /> Edit
              </Button>
            </Link>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardHeader>
      <Separator />
      <CardContent className="flex flex-col mt-4">
        <LabelAndValue label="House No." value={viewData.h_no} />
        <LabelAndValue
          label="Locality or Landmark"
          value={viewData.locality_or_landmark}
        />
        <LabelAndValue label="City" value={viewData.city} />
        <LabelAndValue label="State" value={viewData.state} />
        <LabelAndValue label="Country" value={viewData.country} />
        <LabelAndValue label="Zipcode" value={viewData.zip_code} />
      </CardContent>
      <Separator />
      <CardFooter className="mt-4 flex justify-between">
        <Link to="../personal-details">
          <Button variant={`outline`}>
            <MoveLeftIcon className="mr-2 w-4 h-4" />
            Personal Details
          </Button>
        </Link>
        {/* <Link to="../personal-info">
          <Button variant={`outline`}>
            Address Details
            <MoveRightIcon className="ml-2 w-4 h-4" />
          </Button>
        </Link> */}
      </CardFooter>
    </Card>
  );
};

export default AddressDetailsView;
