import { Card, CardContent } from "@/components/ui/card";
import { CheckCircleIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (queryParams.get("success") === "true") {
      setShowSuccessPage(true);
    } else {
      setShowSuccessPage(false);
      navigate("/login");
    }
  }, [location.search]);

  if (!showSuccessPage) {
    return null;
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <Card className="w-100">
        <CardContent className="flex flex-col justify-center items-center gap-2 pt-5">
          <CheckCircleIcon className="text-green-500 size-10" />
          <p className="text-2xl">Success</p>
          <p>Password reset email sent. Please check your inbox.</p>
          <Link to="/login" className="underline text-sm ">
            Back to Login
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default SuccessPage;
