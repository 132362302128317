import { z } from "zod";

export const LeadStatusSchema = z.object({
  lead_status: z.string(),
});

export const LeadManagerSchema = z.object({
  employee_id: z.string(),
});

export const LeadAssignmentSchema = z.object({
  emp_id: z.string().optional(),
  lead_status: z.string().optional(),
  lead_ids: z.string().array().nonempty(),
});
