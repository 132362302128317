import { Button } from "@/components/ui/button";
import { EducationFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import EducationForm from "@/components/Forms/Assessment/EducationForm";
import { Plus } from "lucide-react";
import { FormCreateDialogComponent } from "../DefaultComponents";
import useStore from "@/lib/store";
import EducationSingleRecord from "./EducationSingleRecord";

type TView = {
  viewData: (z.infer<typeof EducationFormSchema> & { _id: string })[];
};

const EducationsView = ({ viewData }: TView) => {
  const { changeCreateDialogState } = useStore();
  return (
    <div className="flex flex-col gap-4 border rounded-md p-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="text-xl font-semibold">Education Details</p>
          <p className="text-sm text-gray-500">User Education Details</p>
        </div>
        <Button
          className="bg-primary"
          onClick={() => changeCreateDialogState(true)}
        >
          <Plus className="mr-2 w-4 h-4" /> Add Record
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        {viewData &&
          viewData.map((item) => {
            return <EducationSingleRecord item={item} key={item._id} />;
          })}
      </div>

      <FormCreateDialogComponent
        dialog_title="Education Details"
        dialog_desc="User Education Details"
      >
        <EducationForm />
      </FormCreateDialogComponent>
    </div>
  );
};

export default EducationsView;
