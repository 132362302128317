import { Button } from "@/components/ui/button";
import { WorkExpFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { Plus } from "lucide-react";
import { FormCreateDialogComponent } from "../DefaultComponents";
import useStore from "@/lib/store";
import WorkExpForm from "@/components/Forms/Assessment/WorkExpForm";
import WorkExpSingleRecord from "./WorkExpSingleRecord";

type TView = {
  viewData: (z.infer<typeof WorkExpFormSchema> & { _id: string })[];
};

const WorkExpsView = ({ viewData }: TView) => {
  const { changeCreateDialogState } = useStore();
  return (
    <div className="flex flex-col gap-4 border rounded-md p-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="text-xl font-bold">Work Experience Details</p>
          <p className="text-sm text-gray-500">User Work Experience Details</p>
        </div>
        <Button
          className="bg-primary"
          onClick={() => changeCreateDialogState(true)}
        >
          <Plus className="mr-2 w-4 h-4" /> Add Record
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        {viewData &&
          viewData.map((item) => {
            return <WorkExpSingleRecord item={item} key={item._id} />;
          })}
      </div>

      <FormCreateDialogComponent
        dialog_title="Work Experience Details"
        dialog_desc="User Work Experience Details"
      >
        <WorkExpForm />
      </FormCreateDialogComponent>
    </div>
  );
};

export default WorkExpsView;
