import { CardContent, Card } from "@/components/ui/card";
import { Pencil, Trash } from "lucide-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { LabelAndValue } from "../DefaultComponents";
import { EducationFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import EducationForm from "@/components/Forms/Assessment/EducationForm";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

type TSingleRecord = {
  item: z.infer<typeof EducationFormSchema> & { _id: string };
};

const EducationSingleRecord = ({ item }: TSingleRecord) => {
  const axiosInstance = useAxiosAuthInstance();
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogClose = (newValue: boolean) => {
    setIsOpen(newValue);
  };

  const { type, userId } = useParams();
  const recordId = item._id;
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ userId }: { userId: string | undefined }) =>
      await axiosInstance.delete(
        `user/${userId}/educations/${recordId}/${type}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [userId, type, "educations"],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = () => {
    const confirm_response = window.confirm("You want to delete this record?");
    if (!confirm_response) return;
    mutation.mutateAsync({ userId });
    queryClient.invalidateQueries({
      queryKey: [userId, type, "educations"],
    });
  };

  return (
    <Card>
      <CardContent className="flex flex-row justify-between mt-5">
        <div>
          <LabelAndValue label="For" value={item.for} />
          <LabelAndValue
            label="Name of Education"
            value={item.name_of_education}
          />
          <LabelAndValue
            label="Name of Education"
            value={item.field_of_study}
          />
          <LabelAndValue
            label="Field Of Study"
            value={item.board_or_university}
          />
          <LabelAndValue label="State of Study" value={item.state_of_study} />
          <LabelAndValue
            label="County of Study"
            value={item.country_of_study}
          />
          <LabelAndValue
            label="Type of Educational Institute"
            value={item.type_of_educational_institute}
          />
        </div>
        <div className="w-1/4">
          <div className="flex flex-row justify-end gap-4">
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
              <DialogTrigger>
                <Button
                  variant={`outline`}
                  className="text-primary border-primary"
                >
                  <Pencil className="mr-2 w-4 h-4" /> Edit
                </Button>
              </DialogTrigger>
              <DialogContent
                className="max-w-2xl"
                onInteractOutside={(e) => {
                  e.preventDefault();
                }}
              >
                <DialogHeader>
                  <DialogTitle>Education Record</DialogTitle>
                  <Separator />
                </DialogHeader>
                <EducationForm
                  preFormData={item}
                  closeDialog={handleDialogClose}
                />
              </DialogContent>
            </Dialog>
            <Button variant={`danger`} onClick={handleDelete}>
              <Trash className="mr-2 w-4 h-4" /> Delete
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default EducationSingleRecord;
