import { ColumnDef } from "@tanstack/react-table";
import { formatDate } from "@/lib/utils";

export type PaymentProps = {
  _id: string;
  note: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
  }[];
  addedBy: {
    first_name: string;
    last_name: string;
  }[];
};

export const PaymentCols: ColumnDef<PaymentProps>[] = [
  {
    accessorKey: "note",
    header: "Call Summary",
  },
  {
    accessorKey: "user",
    header: "User Name",
    cell: ({ row }) => {
      const user = row.original.user;
      if (user && user.length > 0) {
        const { first_name, last_name } = user[0];
        return `${first_name} ${last_name}`;
      }
      return ""; // Return default value if lead_manager is empty or undefined
    },
  },
  {
    accessorKey: "user",
    header: "User Email",
    cell: ({ row }) => {
      const user = row.original.user;
      if (user && user.length > 0) {
        const { email } = user[0];
        return email;
      }
      return ""; // Return default value if lead_manager is empty or undefined
    },
  },
  {
    accessorKey: "addedBy",
    header: "Added By",
    cell: ({ row }) => {
      const leadManager = row.original.addedBy;
      if (leadManager && leadManager.length > 0) {
        const { first_name, last_name } = leadManager[0];
        return `${first_name} ${last_name}`;
      }
      return ""; // Return default value if lead_manager is empty or undefined
    },
  },
  {
    accessorKey: "createdAt",
    header: "Created On",
    cell: ({ row }) => {
      return formatDate(row.getValue("createdAt"));
    },
  },
];
