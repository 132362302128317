import { LeadManagerSchema } from "@/components/FormInterfaces/LeadSchemas";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormControl,
  FormLabel,
  FormMessage,
  FormItem,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";

type StateProps = {
  _id: string;
  first_name: string;
  last_name: string;
  role: string;
};

const LeadManagerForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const [LeadManagers, setLeadManagers] = useState<StateProps[]>([]);
  const { userId } = useParams();
  const form = useForm<z.infer<typeof LeadManagerSchema>>({
    resolver: zodResolver(LeadManagerSchema),
    defaultValues: {
      employee_id: "",
    },
  });

  const { data } = useQuery({
    queryKey: ["lead_managers"],
    queryFn: async () => await axiosInstance.get(`/admin/lead-managers`),
  });

  useEffect(() => {
    if (data?.data && Array.isArray(data.data) && data.data.length) {
      const employees = data?.data;
      setLeadManagers(() => [...employees]);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof LeadManagerSchema>) =>
      await axiosInstance.patch(`user/${userId}/lead-manager`, formData),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["lead", userId],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof LeadManagerSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-row items-end gap-4"
      >
        <FormField
          control={form.control}
          name="employee_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Lead Manager</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Employee" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {LeadManagers &&
                    LeadManagers.map((item) => {
                      return (
                        <SelectItem value={item._id} key={item._id}>
                          {item.first_name + " " + item.last_name}
                        </SelectItem>
                      );
                    })}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex">
          <Button
            className="bg-primary hover:bg-primary/80 w-full"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LeadManagerForm;
