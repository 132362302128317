import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ChangePasswordForm from "@/components/Forms/Employee/ChangePasswordForm";
import { Separator } from "@/components/ui/separator";

const Settings = () => {
  return (
    <Tabs defaultValue="changePassword">
      <TabsList>
        <TabsTrigger value="changePassword">Change Password</TabsTrigger>
      </TabsList>
      <TabsContent value="changePassword">
        <div className="flex flex-col gap-4 p-5 bg-white">
          <div>
            <p className="text-xl font-medium">Change Password</p>
            <p className="text-gray-500 text-sm">Update Password here</p>
          </div>
          <Separator />
          <ChangePasswordForm />
        </div>
      </TabsContent>
    </Tabs>
  );
};

export default Settings;
