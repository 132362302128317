import { Separator } from "@/components/ui/separator";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { formatDate } from "@/lib/utils";
import { PenBoxIcon } from "lucide-react";
import LeadManagerForm from "@/components/Forms/Lead/LeadManagerForm";
import LeadStatusForm from "@/components/Forms/Lead/LeadStatusForm";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import userIcon from "@/assets/userIcon.png";

type UserProps = {
  first_name: string;
  last_name: string;
  email: string;
  contact_number: string;
  lead_status: string;
  createdAt: string;
  updatedAt: string;
  lead_manager: {
    first_name: string;
    last_name: string;
  };
};

const User = () => {
  const axiosInstance = useAxiosAuthInstance();
  let { userId } = useParams();
  const { data, isPending, isError, error } = useQuery<UserProps>({
    queryKey: ["lead", userId],
    queryFn: () =>
      axiosInstance
        .get(`/users/${userId}`)
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  if (isPending) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  return (
    <div className="flex flex-col gap-4 p-5 rounded-md border bg-white">
      <LeadCard {...data} />
      <Separator />
      <div className="flex gap-12 justify-start items-center flex-wrap">
        <LabelAndValue label="Received On" value={formatDate(data.createdAt)} />

        <div className="flex items-end gap-2">
          <LabelAndValue
            label="Lead Manager"
            value={
              data?.lead_manager
                ? data.lead_manager.first_name +
                  " " +
                  data.lead_manager.last_name
                : ""
            }
          />
          <Popover>
            <PopoverTrigger>
              <PenBoxIcon className="text-primary" />
            </PopoverTrigger>
            <PopoverContent side="top" sideOffset={25} className="w-fit">
              <LeadManagerForm />
            </PopoverContent>
          </Popover>
        </div>

        <div>
          <p className="text-sm text-gray-500 capitalize">Lead Status</p>
          <div className="flex items-end gap-2">
            <span className="px-3 py-1 text-sm w-fit rounded-full bg-primary text-center text-white">
              {data.lead_status}
            </span>
            <Popover>
              <PopoverTrigger>
                <PenBoxIcon className="text-primary" />
              </PopoverTrigger>
              <PopoverContent side="top" sideOffset={25} className="w-fit">
                <LeadStatusForm />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <LabelAndValue
          label="Last Updated On"
          value={formatDate(data.updatedAt)}
        />
      </div>
    </div>
  );
};

const LabelAndValue = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-sm text-gray-500 capitalize">{label}</p>
      <p>{value}</p>
    </div>
  );
};

const LeadCard = ({
  first_name,
  last_name,
  email,
  contact_number,
}: UserProps) => {
  return (
    <div className="flex flex-row justify-start gap-8 items-center">
      <div>
        <img
          src={userIcon}
          alt={first_name}
          className="rounded-full w-28 h-28"
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-3xl font-medium">{first_name + " " + last_name}</p>
        <p className="text-gray-500">{email}</p>
        <p className="text-gray-500">{contact_number}</p>
      </div>
    </div>
  );
};

export default User;
