import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useEffect } from "react";
import { usePageFilters } from "@/lib/store";
import { LEAD_STATUSES } from "@/data/assessment.data";

const FormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "You have to select at least one item.",
  }),
});

export function LeadStatusFilter({ pageName }: { pageName: string }) {
  const { setStatusFilters, pageFilters } = usePageFilters();
  const ContextPageFilters = pageFilters[pageName];

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items:
        ContextPageFilters && ContextPageFilters.statusFilters
          ? ContextPageFilters.statusFilters
          : [],
    },
  });

  const statusFilters = useWatch({
    control: form.control,
    name: "items",
  });

  useEffect(() => {
    if (statusFilters) {
      console.log("lead status filters", statusFilters);
      setStatusFilters(pageName, statusFilters);
    }
  }, [statusFilters]);

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="items"
        render={() => (
          <FormItem>
            <div className="mb-4">
              <FormLabel className="text-base">Lead Status</FormLabel>
              <FormDescription>Select Status to Filter Data</FormDescription>
            </div>
            {LEAD_STATUSES.map((item) => (
              <FormField
                key={item}
                control={form.control}
                name="items"
                render={({ field }) => {
                  return (
                    <FormItem
                      key={item}
                      className="flex flex-row items-start space-x-3 space-y-0"
                    >
                      <FormControl>
                        <Checkbox
                          checked={field.value?.includes(item)}
                          onCheckedChange={(checked) => {
                            return checked
                              ? field.onChange([...field.value, item])
                              : field.onChange(
                                  field.value?.filter((value) => value !== item)
                                );
                          }}
                        />
                      </FormControl>
                      <FormLabel className="font-normal">{item}</FormLabel>
                    </FormItem>
                  );
                }}
              />
            ))}
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
}
