import { Button } from "@/components/ui/button";
import { LanguagesFormSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { Plus } from "lucide-react";
import { FormCreateDialogComponent } from "../DefaultComponents";
import useStore from "@/lib/store";
import LanguagesForm from "@/components/Forms/Assessment/LanguagesForm";
import LangSingleRecord from "./LangSingleRecord";

type TView = {
  viewData: (z.infer<typeof LanguagesFormSchema> & { _id: string })[];
};

const LanguagesView = ({ viewData }: TView) => {
  const { changeCreateDialogState } = useStore();
  return (
    <div className="flex flex-col gap-4 border rounded-md p-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="text-xl font-bold">Language Details</p>
          <p className="text-sm text-gray-500">User Language Details</p>
        </div>
        <Button
          className="bg-primary"
          onClick={() => changeCreateDialogState(true)}
        >
          <Plus className="mr-2 w-4 h-4" /> Add Record
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        {viewData &&
          viewData.map((item) => {
            return <LangSingleRecord item={item} key={item._id} />;
          })}
      </div>

      <FormCreateDialogComponent
        dialog_title="Language Details"
        dialog_desc="User Language Details"
      >
        <LanguagesForm />
      </FormCreateDialogComponent>
    </div>
  );
};

export default LanguagesView;
