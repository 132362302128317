import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { PasswordSchema } from "@/components/FormInterfaces/Employees";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input } from "@/components/ui/input";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";

const ChangePasswordForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const { empId } = useParams();
  const form = useForm<z.infer<typeof PasswordSchema>>({
    resolver: zodResolver(PasswordSchema),
    defaultValues: {
      old_password: "",
      new_password: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof PasswordSchema>) =>
      await axiosInstance.post(`/employee/${empId}/update-password`, formData),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof PasswordSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="old_password"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Old Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="Old Password"
                  {...field}
                  className="max-w-xs"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="new_password"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="New Password"
                  {...field}
                  className="max-w-xs"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="bg-primary hover:bg-primary/80">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default ChangePasswordForm;
