import { z } from "zod";

export const PersonalDetailsFormSchema = z.object({
  destination_country: z.string(),
  visa_type: z.string(),
  alt_contact_number: z.string().optional(),
  alt_email: z.string().email().optional(),
  gender: z.enum(["Male", "Female", "Others"]),
  marital_status: z.string(),
  no_of_children: z.coerce.number().optional(),
  dob: z.string(),
  spouse_dob: z.string().optional(),
  passport_number: z.string().optional(),
  passport_expire_date: z.string().optional(),
});

export const AddressDetailsSchema = z.object({
  h_no: z.string().optional(),
  locality_or_landmark: z.string().optional(),
  city: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  state: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  country: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  zip_code: z.string().optional(),
});

export const EducationFormSchema = z.object({
  for: z.string(),
  name_of_education: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  field_of_study: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  board_or_university: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  state_of_study: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  country_of_study: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  type_of_educational_institute: z.string(),
  highest_level_of_education: z.string(),
});

export const LanguagesFormSchema = z.object({
  for: z.string(),
  language_skill: z.string(),
  speak: z.string(),
  read: z.string(),
  write: z.string(),
  understand: z.string(),
});

export const RelationsFormSchema = z.object({
  relative_rel_with_you: z.string(),
  rel_age: z.string().optional(),
  rel_emp_status: z.string().optional(),
  no_of_people_rel_fin_resp_household_in_can_or_aus: z.string().optional(),
  rel_annual_income: z.string().optional(),
  have_blood_relative_in_can_or_aus: z.string().optional(),
  relative_wish_to_sponser: z.string(),
  full_time_student: z.string(),
  full_time_student_parents_dependent: z.string().optional(),
});

export const WorkExpFormSchema = z.object({
  for: z.string(),
  job_title: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  company_name: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  noc: z.string(),
  duration: z.string(),
  location: z.string(),
  province: z.string().optional(),
  currently_working: z.string(),
  job_qualified_for_social_security: z.string(),
  type_of_employement: z.string(),
  main_duties: z.string().optional(),
});

export const WorkExpCanAusFormSchema = z.object({
  have_been_in_can_or_aus_as_temporary_foreign_worker: z.string(),
  years_worked_fulltime_in_can_or_aus: z.string().optional(),
  currently_employeed_in_can_or_aus: z.string(),
  left_employement_in_can_or_aus: z.string().optional(),
  have_official_offer_from_can_employeer: z.string(),
  have_cert_of_qual_in_trade_occup_by_province: z.string(),
  have_nomination_cert_from_can_province: z.string(),
});

export const LeadNotesSchema = z.object({
  note: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  status: z.string().optional(),
  title: z.string().optional(),
  followup: z.string().optional(),
});

export const ReminderSchema = z.object({
  title: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  followup: z.string(),
  status: z.enum(["pending", "completed", "overdue"]),
  empId: z.string(),
  actionTaken: z.boolean(),
});

export const UserSchema = z.object({
  first_name: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  last_name: z
    .string()
    .trim()
    .refine((value) => value !== "", { message: "Required" }),
  email: z.string().email(),
  contact_number: z.string(),
});
