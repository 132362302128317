import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { LeadNotesSchema } from "@/components/FormInterfaces/Assessment";
import { z } from "zod";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useEffect } from "react";
import useFormHandler from "@/hooks/useFormHandler";

const EditCallNoteForm = ({
  mode = "EDIT",
  recordId,
}: {
  mode: "EDIT";
  recordId?: string;
}) => {
  const axiosInstance = useAxiosAuthInstance();
  const { userId } = useParams();
  const form = useForm<z.infer<typeof LeadNotesSchema>>({
    resolver: zodResolver(LeadNotesSchema),
  });

  const {
    data: preFromData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["callNote", userId, recordId],
    queryFn: async () =>
      await axiosInstance.get(`/user/${userId}/calls/${recordId}`),
    enabled: mode === "EDIT" && !!recordId,
  });

  useEffect(() => {
    if (preFromData?.data) {
      const { note } = preFromData?.data;
      form.reset({ note });
    }
  }, [preFromData?.data]);

  const { successMessage, errorResponse, onSubmit } = useFormHandler({
    mode,
    patchUrl: `/user/${userId}/calls/${recordId}`,
    formSchema: LeadNotesSchema,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      queryClient.invalidateQueries({
        queryKey: ["userCallNotes", userId],
      });
    }
    if (errorResponse) {
      toast.error(errorResponse?.message);
    }
  }, [successMessage, errorResponse]);

  if (isPending) {
    return <p>Fething Data...</p>;
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="note"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Notes</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter Call Notes here..."
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="bg-primary w-full">
          Update
        </Button>
      </form>
    </Form>
  );
};

export default EditCallNoteForm;
