import { z } from "zod";

export const EmployeeSchema = z.object({
  first_name: z
    .string()
    .trim()
    .refine((value) => value !== "", {
      message: "Required",
    }),
  last_name: z
    .string()
    .trim()
    .refine((value) => value !== "", {
      message: "Required",
    }),
  email: z.string().email(),
  contact_number: z.coerce.number(),
  gender: z.enum(["Male", "Female", "Others"]),
});

export const RoleSchema = z.object({
  role: z.string(),
});

export const PasswordSchema = z.object({
  old_password: z
    .string()
    .trim()
    .refine((value) => value !== ""),
  new_password: z
    .string()
    .trim()
    .refine((value) => value !== ""),
});
