import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { formatDate } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { PencilIcon, PlusIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import Avatar from "@/assets/avatar.png";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import EditCallNoteForm from "@/components/Forms/Lead/EditCallNoteForm";
import CreateCallNoteForm from "@/components/Forms/Lead/CreateCallNoteForm";

type CallNoteProps = {
  _id: string;
  note: string;
  addedBy: {
    first_name: string;
    last_name: string;
  };
  createdAt: string;
};

const UserCallNotes = () => {
  return (
    <Card>
      <CardHeader className="border-b px-4">
        <CallNoteActions />
      </CardHeader>
      <CardContent className="flex flex-col p-0">
        <CallNotesContent />
      </CardContent>
    </Card>
  );
};

const CallNoteActions = () => {
  return (
    <div className="flex items-top justify-between flex-wrap gap-4">
      <div>
        <CardTitle>Call Notes</CardTitle>
        <CardDescription>Manage Call Notes here</CardDescription>
      </div>

      <Dialog>
        <DialogTrigger asChild>
          <Button size={`sm`} className="bg-primary">
            <PlusIcon className="mr-2 w-4 h-4" />
            Add Note
          </Button>
        </DialogTrigger>
        <DialogContent className="w-94">
          <DialogHeader>
            <DialogTitle>Update Call Notes</DialogTitle>
            <DialogDescription>
              Manage Call Notes, Reminders, Lead Status all at one place.
            </DialogDescription>
            <Separator />
            <CreateCallNoteForm />
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const CallNotesContent = () => {
  const axiosInstance = useAxiosAuthInstance();
  let { userId } = useParams();
  const { data, isPending, isError, error } = useQuery<CallNoteProps[]>({
    queryKey: ["userCallNotes", userId],
    queryFn: async () =>
      await axiosInstance
        .get(`/user/${userId}/calls`)
        .then((res) => res.data)
        .catch((err) => console.error(err.message)),
  });

  if (isPending) {
    return <p>Fetching Data...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  if (data && !data.length) {
    return <p className="p-4 text-center">No Call Records Found</p>;
  }

  return (
    <>
      {data.map((item, index: number) => {
        return <SingleNote {...item} key={index} />;
      })}
    </>
  );
};

const SingleNote = ({ _id, note, addedBy, createdAt }: CallNoteProps) => {
  return (
    <div className="flex flex-col gap-2 border-b p-4 hover:bg-slate-50">
      <div className="flex flex-row items-center justify-between ">
        <div className="flex flex-row items-center gap-2">
          <img src={Avatar} alt="user" className="w-8 h-8" />
          <div className="flex flex-col">
            <span className="text-sm">
              {addedBy?.first_name + " " + addedBy?.last_name}
            </span>
            <span className="text-gray-500 text-xs">
              {formatDate(createdAt)}
            </span>
          </div>
        </div>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size={`sm`}>
              <PencilIcon className="w-4 h-4 text-primary" />
            </Button>
          </PopoverTrigger>
          <PopoverContent side="left">
            <EditCallNoteForm mode="EDIT" recordId={_id} />
          </PopoverContent>
        </Popover>
      </div>
      <p className="text-sm text-gray-500 ml-10">{note}</p>
    </div>
  );
};

export default UserCallNotes;
