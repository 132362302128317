import { jwtDecode } from "jwt-decode";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { format, formatISO, parseISO } from "date-fns";

// interface AuthUserObject extends JwtPayload {
//   username: string;
//   roles: string[];
//   name: string;
// }

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date: string) => {
  if (!date) {
    return ""; // Handle null or undefined dates as needed
  }
  return format(new Date(date), "dd/MM/yyyy HH:mm");
};

export const formatDOB = (date: string) => {
  if (!date) {
    return ""; // Handle null or undefined dates as needed
  }
  return format(new Date(date), "dd/MM/yyyy");
};

export const InputDateFormat = (dateString: string) => {
  if (!dateString) return "";
  return formatISO(parseISO(dateString), { representation: "date" });
};

export const serverErrorMessage = (error: any) => {
  let err_message = "";
  if (error && error.response && error.response.data) {
    err_message = error.response.data.message;
  } else if (error && !error.response) {
    err_message = error.message;
  } else {
    err_message = "Error Occured";
  }
  return err_message;
};

export const decodeJwtToken = (token: string) => {
  if (!token) return null;
  return jwtDecode(token);
};

// axiosInstance.interceptors.response.use(
//   (response) => {
//     // If the response is successful, return it
//     return response;
//   },
//   (error) => {
//     // Handle errors
//     if (error.response && error.response.status === 401) {
//       // Perform logout or any other action you want
//       // For example, clearing authentication tokens, redirecting to login page, etc.
//       // Assuming you have a function to handle logout
//       deleteToken();
//     }
//     return Promise.reject(error);
//   }
// );
