import AddressDetailsView from "@/components/FormViews/Assessment/AddressDetailsView";
import PersonalDetailsView from "@/components/FormViews/Assessment/PersonalDetailsView";
import RelationsView from "@/components/FormViews/Assessment/RelationsView";
import WorkExpCanAusView from "@/components/FormViews/Assessment/WorkExpCanAusView";
import AddressForm from "@/components/Forms/Assessment/AddressForm";
import PersonalDetailsForm from "@/components/Forms/Assessment/PersonalDetailsForm";
import RelationsForm from "@/components/Forms/Assessment/RelationsForm";
import WorkExpCanAusForm from "@/components/Forms/Assessment/WorkExpCanAusForm";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

const CrmFormsWrapper = () => {
  const axiosInstance = useAxiosAuthInstance();
  const location = useLocation();
  const { userId } = useParams();
  let resource = location.pathname.split("/").pop();

  const { data, isPending, isError, error } = useQuery({
    queryKey: [userId, resource],
    queryFn: async () => await axiosInstance.get(`/user/${userId}/${resource}`),
  });

  if (isPending) {
    return <p>Checking Resource...</p>;
  }

  if (isError) {
    return <p>Error Occured {error.message}</p>;
  }

  if (data?.data) {
    return (
      <>
        {resource === "personal-details" && (
          <PersonalDetailsView viewData={data?.data} />
        )}
        {resource === "address" && <AddressDetailsView viewData={data?.data} />}
        {resource === "work-in-can-aus" && (
          <WorkExpCanAusView viewData={data?.data} />
        )}
        {resource === "relations" && <RelationsView viewData={data?.data} />}
      </>
    );
  } else {
    return (
      <>
        {resource === "personal-details" && <PersonalDetailsForm />}
        {resource === "address" && <AddressForm />}
        {resource === "work-in-can-aus" && <WorkExpCanAusForm />}
        {resource === "relations" && <RelationsForm />}
      </>
    );
  }
};

export default CrmFormsWrapper;
