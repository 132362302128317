import { Button } from "@/components/ui/button";
import { formatDate } from "@/lib/utils";
import {
  createColumnHelper,
  SortDirection,
  Column,
} from "@tanstack/react-table";
import { ArrowDown, ArrowUp, ArrowUpDown } from "lucide-react";

export type TWebLead = {
  name: string;
  email: string;
  contact_number: string;
  message: string;
  createdAt: string;
};

type TSortingComponent = {
  title: string;
  isSorted: false | SortDirection;
  column: Column<TWebLead, string>;
};

const SortingComponent = ({ title, isSorted, column }: TSortingComponent) => {
  return (
    <div className="flex items-center gap-2">
      {title}
      <Button
        variant={isSorted ? "blue" : "outline"}
        size="sm"
        onClick={() => column.toggleSorting()}
      >
        {isSorted === "asc" ? (
          <ArrowDown className="h-4 w-4" />
        ) : isSorted === "desc" ? (
          <ArrowUp className="h-4 w-4" />
        ) : (
          <ArrowUpDown className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

const columnHelper = createColumnHelper<TWebLead>();

export const WebLeadCols = [
  columnHelper.accessor("name", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Name" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("email", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Email" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("contact_number", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Contact" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("message", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent title="Message" isSorted={isSorted} column={column} />
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdAt", {
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <SortingComponent
          title="Received On"
          isSorted={isSorted}
          column={column}
        />
      );
    },
    cell: (props) => formatDate(props.getValue()),
  }),
];
